import {
  usePageHistoryUpdate,
  useRestrictedAccess,
  useSyncAuthState,
} from "app/App.hooks";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { scrollToTop } from "shared/lib";
import "shared/styles/common.scss";
import "shared/styles/reset.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/scss/navigation";
import "./App.scss";
import { RouteList } from "./routes/Routes";

export default function App() {
  usePageHistoryUpdate();
  useSyncAuthState();
  useRestrictedAccess({
    restrictedPaths: [
      "/products/register",
      "/myshop",
      "/products/edit",
      "/direct-purchase",
      "/apply-cooperation",
      "/help/illegal-deal",
      "/help/fake",
      "/help/report",
      "/talk",
    ],
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return <RouteList />;
}
