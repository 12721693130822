import { useMutation } from "@tanstack/react-query";
import { showAlert } from "shared/lib/showAlert";
import type { APIAxiosError } from "shared/types/server";
import type { FileUploadPayload } from "./fileService";
import { fileService } from "./fileService";

export function useFileUpload() {
  return useMutation({
    mutationFn: (payload: FileUploadPayload) => fileService.upload(payload),
    onError: (error: APIAxiosError) => {
      const errorCode = error.response?.data?.data?.errorCode;
      const errorMessage = error.response?.data?.message;
      showAlert.error({
        errorConditions: [
          "ERR_000_001_001",
          "ERR_000_001_002",
          "ERR_000_001_003",
          "ERR_000_001_004",
          "ERR_000_001_005",
          "ERR_000_001_006",
          "ERR_000_001_007",
          "ERR_000_001_008",
          "ERR_000_001_009",
        ],
        errorCode,
        errorMessage,
      });
    },
  });
}
