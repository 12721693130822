import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

export const DirectPurchaseRoutes = () => {
  const DirectPurchase = lazy(() =>
    import("../../modules/boards/DirectPurchase/DirectPurchaseBoard.view").then(
      module => ({
        default: module.DirectPurchase,
      })
    )
  );
  const DirectPurchaseDetail = lazy(() =>
    import(
      "../../modules/boards/DirectPurchase/DirectPurchaseDetail.view"
    ).then(module => ({
      default: module.DirectPurchaseDetail,
    }))
  );
  const DirectPurchaseRegister = lazy(() =>
    import(
      "../../modules/boards/DirectPurchase/DirectPurchaseRegister.view"
    ).then(module => ({
      default: module.DirectPurchaseRegister,
    }))
  );
  const DirectPurchaseEdit = lazy(() =>
    import("../../modules/boards/DirectPurchase/DirectPurchaseEdit.view").then(
      module => ({
        default: module.DirectPurchaseEdit,
      })
    )
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <DirectPurchase />
          </Suspense>
        }
      />
      <Route
        path="/:boardId"
        element={
          <Suspense fallback={null}>
            <DirectPurchaseDetail />
          </Suspense>
        }
      />
      <Route
        path="/register"
        element={
          <Suspense fallback={null}>
            <DirectPurchaseRegister />
          </Suspense>
        }
      />
      <Route
        path="/:boardId/edit"
        element={
          <Suspense fallback={null}>
            <DirectPurchaseEdit />
          </Suspense>
        }
      />
    </Routes>
  );
};
