import type { CSSProperties, ReactNode } from "react";
import type { BottomSheetProps } from "./BottomSheet";
import { BottomSheet } from "./BottomSheet";
import { useBottomSheetScroll } from "./useBottomSheetScroll";

export type ScrolledBottomSheetProps = BottomSheetProps & {
  height?: CSSProperties["height"];
  maxHeight?: CSSProperties["maxHeight"];
  minHeight?: CSSProperties["minHeight"];
  fixedChildren?: ReactNode;
};

export const ScrolledBottomSheet = ({
  height,
  maxHeight,
  minHeight,
  children,
  fixedChildren,
  ...rest
}: ScrolledBottomSheetProps) => {
  const { canSwipe, ref } = useBottomSheetScroll();

  return (
    <BottomSheet canSwipe={canSwipe} {...rest}>
      <div
        ref={ref}
        style={{ height, maxHeight, minHeight, overflowY: "scroll" }}
      >
        {children}
      </div>
      {fixedChildren}
    </BottomSheet>
  );
};
