import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import orderSlice from "../store/features/order/orderSlice";
import scrollSlice from "../store/features/scroll/scrollSlice";
import searchSlice from "../store/features/search/searchSlice";
import userSlice from "../store/features/user/userSlice";

const persistConfig = {
  key: "root",
  storage, // local storage에 store state 저장
};
const rootReducers = combineReducers({
  user: userSlice.reducer,
  order: orderSlice.reducer,
  search: searchSlice.reducer,
  scroll: scrollSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
