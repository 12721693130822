import { useEffect, useRef, useState } from "react";
import { useDebounce } from "shared/hooks";

export const useBottomSheetScroll = () => {
  const [canSwipe, setCanSwipe] = useState(true);
  const isInteractingRef = useRef(false); // 터치 또는 마우스 사용 중인지 여부
  const ref = useRef<HTMLDivElement | null>(null);

  // 스크롤이 끝난 후에 실행될 함수
  const handleScrollEnd = useDebounce(() => {
    if (!isInteractingRef.current) {
      if (ref.current?.scrollTop === 0) {
        setCanSwipe(true);
      } else {
        setCanSwipe(false);
      }
    }
  }, 150); // 150ms 후에 스크롤이 멈춘 것으로 간주

  useEffect(() => {
    const handleScroll = () => {
      if (!isInteractingRef.current) {
        setCanSwipe(false); // 스크롤 중에는 canSwipe을 false로 설정
      }
      handleScrollEnd(); // 디바운스된 함수 호출
    };

    const handleTouchStart = () => {
      isInteractingRef.current = true;
      if (ref.current?.scrollTop !== 0) {
        setCanSwipe(false);
      }
    };

    const handleTouchEnd = () => {
      isInteractingRef.current = false;
      handleScrollEnd();
    };

    const handleMouseDown = () => {
      isInteractingRef.current = true;
      if (ref.current?.scrollTop !== 0) {
        setCanSwipe(false);
      }
    };

    const handleMouseUp = () => {
      isInteractingRef.current = false;
      handleScrollEnd();
    };

    const element = ref.current;
    element?.addEventListener("scroll", handleScroll);
    element?.addEventListener("touchstart", handleTouchStart);
    element?.addEventListener("touchend", handleTouchEnd);
    element?.addEventListener("mousedown", handleMouseDown);
    element?.addEventListener("mouseup", handleMouseUp);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
      element?.removeEventListener("touchstart", handleTouchStart);
      element?.removeEventListener("touchend", handleTouchEnd);
      element?.removeEventListener("mousedown", handleMouseDown);
      element?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleScrollEnd]);

  return {
    canSwipe,
    ref,
  };
};
