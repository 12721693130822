import classnames from "classnames";
import { forwardRef } from "react";
import Shared from "..";
import { Spinner } from "../Spinner/Spinner";
import { BaseButton } from "./BaseButton";
import styles from "./Button.module.scss";
import type { ButtonProps } from "./types";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      text,

      styleVariant = "outlined",
      colorVariant = "gray",
      size = "m",
      fullWidth,

      isLoading,
      loadingText,
      loadingSpinnerSize = "s",
      ...rest
    },
    ref
  ) => {
    return (
      <BaseButton
        className={classnames(
          styles.Button,
          styles[`style-${styleVariant}`],
          styles[`color-${colorVariant}`],
          styles[`size-${size}`],
          fullWidth && styles.fullWidth,
          isLoading && styles.isLoading,
          className
        )}
        ref={ref}
        {...rest}
      >
        {children}
        <span className={styles.text}>{text}</span>

        {isLoading && (
          <Shared.Overlay
            position="absolute"
            style={{ borderRadius: 6, background: "#ffffff00", zIndex: 1 }}
          >
            <Shared.HStack
              justify="center"
              align="center"
              style={{ width: "100%", height: "100%" }}
            >
              <Spinner
                size={loadingSpinnerSize}
                colorVariant={styleVariant === "solid" ? "white" : colorVariant}
              />
              {loadingText && (
                <span className={styles.loadingText}>{loadingText}</span>
              )}
            </Shared.HStack>
          </Shared.Overlay>
        )}
      </BaseButton>
    );
  }
);
