import { LoadingPage } from "@components/zLegacyLayouts/Loading/LoadingPage";
import { BottomNavigation } from "@components/zLegacyLayouts/Navigations/BottomNavigation";
import { useEffect, type HTMLAttributes, type ReactNode } from "react";
import { scrollToTop } from "shared/lib";
import styles from "./ItemListLayout.module.scss";

export type ItemListLayoutProps = HTMLAttributes<HTMLDivElement> & {
  itemCount: number | undefined;
  CustomSkeleton?: ReactNode;
  isFetching: boolean;
  isLoading: boolean;
  noNavigation?: boolean;

  panelHeight?: number;

  children: ReactNode;
};

export const ItemListLayout = ({
  itemCount,
  CustomSkeleton = <LoadingPage spinner />,
  isLoading,
  isFetching,
  children,
  noNavigation,
  panelHeight,
  ...rest
}: ItemListLayoutProps) => {
  const showSkeleton = isLoading || isFetching;

  useEffect(() => {
    isLoading && scrollToTop();
  }, [isLoading]);

  return (
    <div className={styles.ItemListLayout} {...rest}>
      <div
        className={styles.itemList}
        style={{
          minHeight: `calc(100vh - var(--size-gnb) - var(--size-bottom-navigation) ${panelHeight ? `- ${panelHeight}px` : ""})`,
        }}
      >
        {children}

        {!isLoading && itemCount === 0 && (
          <div className={styles.emptyView}>조회 결과가 없습니다.</div>
        )}

        {showSkeleton && CustomSkeleton}
      </div>

      {!noNavigation && <BottomNavigation noMargin />}
    </div>
  );
};
