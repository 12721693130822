import { useAppSelector } from "app/appStore";
import { LegacyBackIcon } from "../../zLegacyAtom/LegacyIcons/BackIcon";
import { LegacyCloseIcon } from "../../zLegacyAtom/LegacyIcons/CloseIcon";
import { LegacyLogoIcon } from "../../zLegacyAtom/LegacyIcons/LogoIcon";
import { Header } from "./Header";
import "./Header.scss";

export const TitleHeader = (props: {
  title: string;
  type: string;
  closeRoute: string | number;
  backRoute: string | number;
  onClick: VoidFunction;
}) => {
  const { title, type, closeRoute, backRoute, onClick } = props;
  const iconType = ["X"].includes(type) ? type : "default";
  const { navigationPath } = useAppSelector(state => state.user);
  const closeRouteFC = () => {
    if (closeRoute) {
      return closeRoute;
    }
    if (navigationPath?.from === navigationPath?.to) {
      return "/";
    }
    return -1;
  };

  return (
    <Header id="TitleHeader">
      <div className="left">
        <div className="left1">
          {iconType === "X" ? (
            <LegacyCloseIcon route={closeRouteFC()} handleClick={onClick} />
          ) : (
            <LegacyBackIcon route={backRoute} />
          )}
        </div>
        <div className="left2">
          <LegacyLogoIcon />
        </div>
      </div>
      <title>{title}</title>
      <div className="right" style={{ width: "80px" }} />
    </Header>
  );
};

TitleHeader.defaultProps = {
  type: "",
  closeRoute: -1,
  backRoute: -1,
  onClick: () => undefined,
};
