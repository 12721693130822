import { removeLocalDataForSignOut } from "features/auth/sign-out";
import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

const FindInfo = lazy(() =>
  import("../../legacyPages/sign/FindInfo.view").then(module => ({
    default: module.FindInfo,
  }))
);
const FindPassword = lazy(() =>
  import("../../modules/Sign/SignIn/FindPassword.module").then(module => ({
    default: module.FindPassword,
  }))
);
const IdSearchResult = lazy(() =>
  import("../../legacyPages/sign/IdSearchResult.view").then(module => ({
    default: module.IdSearchResult,
  }))
);
const ChangePassword = lazy(() =>
  import("../../legacyPages/sign/ChangePassword.view").then(module => ({
    default: module.ChangePassword,
  }))
);
const ChangePasswordComplete = lazy(() =>
  import("../../legacyPages/sign/ChangePasswordComplete.view").then(module => ({
    default: module.ChangePasswordComplete,
  }))
);

export const FindRoutes = () => {
  useEffect(() => {
    removeLocalDataForSignOut();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <FindInfo />
          </Suspense>
        }
      >
        <Route
          path="id"
          element={
            <Suspense fallback={null}>
              <FindInfo />
            </Suspense>
          }
        />
        <Route
          path="pw"
          element={
            <Suspense fallback={null}>
              <FindPassword />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="id/result"
        element={
          <Suspense fallback={null}>
            <IdSearchResult />
          </Suspense>
        }
      />
      <Route
        path="pw/change"
        element={
          <Suspense fallback={null}>
            <ChangePassword />
          </Suspense>
        }
      />
      <Route
        path="pw/complete"
        element={
          <Suspense fallback={null}>
            <ChangePasswordComplete />
          </Suspense>
        }
      />
    </Routes>
  );
};
