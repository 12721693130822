import classNames from "classnames";
import type { ReactNode } from "react";
import styles from "./Header.module.scss";

type Props = {
  className?: string;
  children: ReactNode;
};

export const Header = ({ className, children }: Props) => {
  return (
    <header className={classNames(styles.Header, className)}>{children}</header>
  );
};
