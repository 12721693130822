import { BottomNavigation } from "@components/zLegacyLayouts/Navigations/BottomNavigation";
import classNames from "classnames";
import type { CSSProperties, ReactNode } from "react";
import styles from "./Layout.module.scss";

type LayoutProps = {
  children: ReactNode;

  noNavigation?: boolean;
  colorVariants?: "white" | "gray";
  padding?: CSSProperties["padding"];
  style?: CSSProperties;
};

export const Layout = ({
  children,
  noNavigation,
  padding,
  colorVariants = "white",
  style,
}: LayoutProps) => {
  return (
    <div
      className={classNames(styles.Layout, styles[`color-${colorVariants}`])}
    >
      <div className={styles.contents} style={{ padding, ...style }}>
        {children}
      </div>
      {!noNavigation && <BottomNavigation noMargin />}
    </div>
  );
};
