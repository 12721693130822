import Skeleton from "react-loading-skeleton";
import { RoundedCard } from "../Card/Card";
import { TitleHeader } from "../Headers/TitleHeader";
import "./Skeleton.scss";

export const CartListSkeleton = () => {
  let arr = [];
  for (let index = 0; index < 4; index++) {
    arr.push(<CartListCardSkeleton key={index} />);
  }
  return (
    <>
      <TitleHeader title={"장바구니"} type="<" />
      <div id="CartListSkeleton" style={{ marginTop: "70px" }}>
        {arr}
      </div>
    </>
  );
};

export const CartListCardSkeleton = () => {
  return (
    <div id="CartListCardSkeleton">
      <RoundedCard>
        <Skeleton width={"20%"} className="contents_skeleton" />
        <section />
        <div className="contents_wrap">
          <Skeleton width={"80px"} height={"80px"} className="image_skeleton" />
          <div style={{ width: "100%" }}>
            <Skeleton width={"80%"} className="contents_skeleton" />
            <Skeleton width={"80%"} className="contents_skeleton" />
            <Skeleton width={"50%"} className="contents_skeleton" />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Skeleton
            width={"150px"}
            className="button_skeleton"
            style={{ marginLeft: "13px" }}
          />
          <Skeleton
            width={"80px"}
            className="button_skeleton"
            style={{ marginRight: "13px" }}
          />
        </div>
      </RoundedCard>
    </div>
  );
};

export const HeaderSkeleton = () => {
  return (
    <div id="HeaderSkeleton">
      {/* <RoundedCard>
    <Skeleton></Skeleton>
    </RoundedCard> */}
    </div>
  );
};
