import { useNavigate } from "react-router-dom";

export const LegacyCloseIcon = ({ route, handleClick }) => {
  const navigate = useNavigate();

  return (
    <img
      className="close_icon"
      style={{ width: "37px", height: "40px" }}
      src="/assets/icons/icon_navi_close.svg"
      alt="사진없음"
      onClick={() => {
        navigate(route);
        handleClick();
      }}
    />
  );
};

LegacyCloseIcon.defaultProps = {
  route: -1,
  handleClick: () => undefined,
};
