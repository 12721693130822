import classNames from "classnames";
import { DeliveryType } from "entities/seller-product";
import styles from "./ProductModules.module.scss";

const getShippingText = (deliveryType: number, deliveryFee: number) => {
  if (deliveryType === DeliveryType.무료) return "무료배송";
  if (deliveryType === DeliveryType.유료_착불) return "착불";
  if (deliveryType === DeliveryType.유료_선결제 && deliveryFee === 0)
    return "묶음배송";
  return `배송비 ${deliveryFee}원`;
};

type Props = {
  deliveryType: number;
  deliveryFee: number;
  deliveryOverseas: boolean;
  className?: string;
};

export const ProductShipping = ({
  deliveryType,
  deliveryFee,
  deliveryOverseas,
  className,
}: Props) => {
  return (
    <div className={classNames(styles.ProductShipping, className, "text13")}>
      <span className="graytext">
        {getShippingText(deliveryType, deliveryFee)}
      </span>
      {deliveryOverseas && <span className="graytext"> · 해외배송</span>}
    </div>
  );
};
