import { Spinner } from "@components/zLegacyAtom/Spinner/Spinner";
import { useLockBodyScroll } from "shared/hooks/useLockBodyScroll/useLockBodyScroll";
import "./LoadingPage.scss";

interface IProps {
  spinner?: boolean;
}

export const LoadingPage = ({ spinner = false }: IProps) => {
  useLockBodyScroll();

  return <div id="LoadingPage">{spinner && <Spinner />}</div>;
};
