import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
// import { DeliveryAddress } from "../modules/Checkout/DeliveryAddress.module";
import { EditDeliveryAddr } from "../../legacyPages/user/CheckoutAddr";
// import { NonMemberOrderList } from "../user/NonMemberOrderList.view";
import { RefundAccountChange } from "../../legacyPages/user/RefundAccountChange";
// import { ShippingAddress } from "../user/ShippingAddress.view";

const OrderDetail = lazy(() =>
  import("../../legacyPages/user/OrderDetail.view").then(module => ({
    default: module.OrderDetail,
  }))
);
const DeliveryTracking = lazy(() =>
  import("../../legacyPages/user/DeliveryTracking.view").then(module => ({
    default: module.DeliveryTracking,
  }))
);
const ReturnRequest = lazy(() =>
  import("../../legacyPages/user/ReturnRequest.view").then(module => ({
    default: module.ReturnRequest,
  }))
);
const ExchangeInfo = lazy(() =>
  import("../../legacyPages/user/ExchangeInfo.view").then(module => ({
    default: module.ExchangeInfo,
  }))
);
const ExchangeRequest = lazy(() =>
  import("../../legacyPages/user/ExchangeRequest.view").then(module => ({
    default: module.ExchangeRequest,
  }))
);
const CancelInfo = lazy(() =>
  import("../../legacyPages/user/CancelInfo.view").then(module => ({
    default: module.CancelInfo,
  }))
);
const ReturnInfoInquiry = lazy(() =>
  import("../../legacyPages/user/ReturnInfo.view").then(module => ({
    default: module.ReturnInfo,
  }))
);
const PurchaseOrdersPage = lazy(() =>
  import("pages/myshop/orders").then(module => ({
    default: module.PurchaseOrdersPage,
  }))
);
const OrderCancel = lazy(() =>
  import("pages/myshop/orders/cancel-request").then(module => ({
    default: module.OrderCancelPage,
  }))
);

export const MyshopBuyTabRoutes = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <Suspense fallback={null}>
            <PurchaseOrdersPage />
          </Suspense>
        }
      />
      <Route
        path="delivery-info"
        element={
          <Suspense fallback={null}>
            <DeliveryTracking />
          </Suspense>
        }
      />
      <Route
        path="return-request"
        element={
          <Suspense fallback={null}>
            <ReturnRequest />
          </Suspense>
        }
      />
      <Route
        path="exchange-info"
        element={
          <Suspense fallback={null}>
            <ExchangeInfo />
          </Suspense>
        }
      />
      <Route
        path="exchange-request"
        element={
          <Suspense fallback={null}>
            <ExchangeRequest />
          </Suspense>
        }
      />
      <Route
        path="return-info"
        element={
          <Suspense fallback={null}>
            <ReturnInfoInquiry />
          </Suspense>
        }
      />
      <Route
        path="cancel-info"
        element={
          <Suspense fallback={null}>
            <CancelInfo />
          </Suspense>
        }
      />
      <Route
        path="cancel-request"
        element={
          <Suspense fallback={null}>
            <OrderCancel />
          </Suspense>
        }
      />

      <Route
        path="order-detail"
        element={
          <Suspense fallback={null}>
            <OrderDetail />
          </Suspense>
        }
      />
      <Route
        path="refund-account-change"
        element={
          <Suspense fallback={null}>
            <RefundAccountChange />
          </Suspense>
        }
      />
      <Route
        path="address-edit"
        element={
          <Suspense fallback={null}>
            <EditDeliveryAddr />
          </Suspense>
        }
      />
      <Route
        path="delivery-tracking"
        element={
          <Suspense fallback={null}>
            <DeliveryTracking />
          </Suspense>
        }
      />
    </Routes>
  );
};
