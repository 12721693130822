import { useEffect } from "react";
import DaumPostcode from "react-daum-postcode";
import { useNavigate } from "react-router-dom";
import "./style/ModalPostCode.scss";

// 다음 우편번호 검색 API

export const ModalPostCode = ({
  setClose,
  setAddress,
  setZoneCode,
  setJibunAddress,
  setValue,
}: {
  setClose: any;
  setAddress: any;
  setZoneCode: any;
  setJibunAddress: any;
  setValue: any;
}) => {
  useEffect(() => {
    document.body.setAttribute("style", "overflow: hidden");

    return () => document.body.setAttribute("style", "overflow: auto");
  }, []);

  return (
    <div id="ModalPostCode">
      <Postcode
        setAddress={setAddress}
        setZoneCode={setZoneCode}
        setJibunAddress={setJibunAddress}
        setClose={setClose}
        setValue={setValue}
      />
      <button onClick={() => setClose(false)}>닫기</button>
    </div>
  );
};

const Postcode = ({
  setAddress,
  setClose,
  setZoneCode,
  setJibunAddress,
  setValue,
}: {
  setAddress: any;
  setClose: any;
  setZoneCode: any;
  setJibunAddress: any;
  setValue: any;
}) => {
  const navigate = useNavigate();
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    // input value={address}에 fullAddress 넣어주기
    // input value={zoneCode}에 data.zonecode 넣어주기
    // input value={jibunAddress}에 data.autoJibunAddress 넣어주기
    setAddress(fullAddress && fullAddress);
    setZoneCode(data && data.zonecode);
    setJibunAddress(
      data && (data.jibunAddress ? data.jibunAddress : data.autoJibunAddress)
    );
    // react-hook-form value
    setValue(
      "addressLegacy",
      data.jibunAddress ? data.jibunAddress : data.autoJibunAddress
    );
    setValue("addressRoad", data.roadAddress);
    setValue("postcode", data.zonecode);

    // 일정 값이 입력된 게 확인되면 자동으로 modalPostCode창 닫히기
    if (fullAddress && data.zonecode) {
      setClose(false);
      navigate(-1);
    }
  };
  return (
    <DaumPostcode
      onComplete={handleComplete}
      style={{
        width: "100%",
        height: "75%",
        border: "1px solid rgba(0, 0, 0, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 11,
      }}
    />
  );
};
