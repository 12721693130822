import { forwardRef } from "react";
import type { InputProps } from "..";
import Shared from "..";
import type { InputLabelProps } from "./InputLabel";
import { InputLabel } from "./InputLabel";

type Props = InputProps & InputLabelProps;

export const LabeledInput = forwardRef<HTMLInputElement, Props>(
  ({ label, style, id, error, errorMessage, ...inputProps }, ref) => {
    const inputId = `label-id-${label}-${id}`;

    return (
      <InputLabel
        label={label}
        id={inputId}
        style={style}
        error={error}
        errorMessage={errorMessage}
      >
        <Shared.Input id={inputId} error={error} ref={ref} {...inputProps} />
      </InputLabel>
    );
  }
);
