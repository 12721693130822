import { LegacySellProductIcon } from "@components/zLegacyAtom/LegacyIcons/SellProductIcon";
import { useNavigate } from "react-router-dom";
import { LegacyCartIcon } from "../../zLegacyAtom/LegacyIcons/CartIcon";
import { LegacySearchIcon } from "../../zLegacyAtom/LegacyIcons/SearchIcon";
import { Header } from "./Header";
import "./Header.scss";

type Props = {
  title?: string;
  hasRightIcons?: boolean;
  setOpen?: any;
  backRoute?: any;
};

export const PopupDefaultHeader = ({
  title,
  hasRightIcons,
  setOpen,
  backRoute = -1,
}: Props) => {
  const navigate = useNavigate();
  const backFC = () => {
    navigate(backRoute);
  };
  const logoFC = () => {
    setOpen?.(false);
    navigate("/");
  };

  return (
    <Header id="default_header">
      <div className="left">
        <div className="left1" onClick={backFC}>
          <img
            style={{ width: "20px", height: "20px" }}
            src="/assets/icons/icon_left_arrow.svg"
            alt="사진없음"
          />
        </div>

        <div className="left2" onClick={logoFC}>
          <img
            style={{ width: "40px", height: "40px" }}
            src="/assets/icons/icon_logo.svg"
            alt="사진없음"
          />
        </div>
      </div>
      <title>{title}</title>
      <div className="right">
        {hasRightIcons && (
          <>
            <LegacySearchIcon />
            <LegacyCartIcon />
            <LegacySellProductIcon />
          </>
        )}
      </div>
    </Header>
  );
};
