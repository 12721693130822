import classNames from "classnames";
import type { CSSProperties } from "react";
import { formatPrice } from "shared/lib/formatPrice";
import styles from "./ProductModules.module.scss";

type Props = {
  salePrice: number;
  orderQuantity: number;
  displayTotal?: boolean;
  totalPrice?: number;

  textAlign?: CSSProperties["textAlign"];
  className?: string;
};

export const ProductPrice = ({
  salePrice,
  orderQuantity,
  displayTotal,
  totalPrice,
  textAlign,
  className,
}: Props) => {
  return (
    <div
      className={classNames(styles.ProductPrice, className)}
      style={{ textAlign }}
    >
      <div className="graytext">
        <span>{formatPrice(salePrice, { suffix: "원" })}</span>
        <span> · {orderQuantity}개</span>
      </div>

      {displayTotal && (
        <div className={styles.totalPrice}>
          {formatPrice(totalPrice ?? salePrice * orderQuantity, {
            suffix: "원",
          })}
        </div>
      )}
    </div>
  );
};
