import type { SkeletonProps } from "react-loading-skeleton";
import ReactSkeleton from "react-loading-skeleton";

export const Skeleton = ({
  width = "100%",
  height = "16px",
  ...props
}: SkeletonProps) => {
  return (
    <div style={{ width }}>
      <ReactSkeleton height={height} {...props} />
    </div>
  );
};
