import { Link } from "react-router-dom";

export const LegacySearchIcon = () => {
  return (
    <Link to="/search" style={{ width: "40px", height: "40px" }}>
      <img src="/assets/icons/icon_navi_search.svg" alt="SearchIcon" />
    </Link>
  );
};

export const LegacySearchIconImg = ({ onClick }) => {
  return (
    <img
      className="search_icon"
      onClick={onClick || null}
      src="/assets/icons/icon_navi_search.svg"
      alt="SearchIcon"
      style={{ width: "40px", height: "40px", backgroundColor: "white" }}
    />
  );
};

LegacySearchIconImg.defaultProps = {
  onClick: null,
};
