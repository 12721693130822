import type { ProductImage } from "entities/seller-product";
import { useCallback, useEffect, useRef, useState } from "react";
import type { ImageUploaderProps, PreviewImage } from "../types";

type Props = Pick<ImageUploaderProps, "onChange" | "defaultImages">;

export const useInitializePreviewImages = ({
  defaultImages = [],
  onChange,
}: Props) => {
  const [previewImages, setPreviewImages] = useState<PreviewImage[]>([]);
  const initialized = useRef(false);

  const initializeImages = useCallback(
    (images: ProductImage[]) => {
      setPreviewImages(
        images.map(({ imagePath }) => ({
          id: imagePath,
          imagePath,
        }))
      );
    },
    [setPreviewImages]
  );

  const hasBlob = useCallback(
    (images: ProductImage[]) =>
      images.some(({ imagePath }) => imagePath.includes("blob:http")),
    []
  );

  /**
   * defaultImages가 설정되었을 때 초기화 및 설정 로직을 수행합니다.
   */
  useEffect(() => {
    if (
      defaultImages.length > 0 &&
      !hasBlob(defaultImages) &&
      !initialized.current
    ) {
      initializeImages(defaultImages);
      initialized.current = true;
    }
  }, [defaultImages, hasBlob, initializeImages]);

  /**
   * previewImages 상태가 변경될 때마다
   * onChange 콜백을 호출하여 상위 컴포넌트에 변경사항을 알립니다.
   */
  useEffect(() => {
    onChange?.(previewImages.map(({ imagePath }) => ({ imagePath })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImages]);

  return {
    previewImages,
    setPreviewImages,
  };
};
