import "./Wrap.scss";

export const BottomBtnWrap = (props: {
  btn1: Object;
  btn2: Object;
  btn3?: any;
  onClick?: () => void;
}) => {
  const { btn1, btn2, btn3, onClick } = props;

  return (
    <div id="BottomBtnWrap" onClick={onClick}>
      {btn1}
      {btn2}
      {btn3}
    </div>
  );
};

BottomBtnWrap.defaultProps = {
  btn2: undefined,
  onClick: () => undefined,
};
