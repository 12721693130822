import { ProductBadges } from "./ProductBadges";
import { ProductChannelInfo } from "./ProductChannelInfo";
import { ProductDeliveryTracking } from "./ProductDeliveryTracking";
import { ProductImage } from "./ProductImage";
import { ProductName } from "./ProductName";
import { ProductOption } from "./ProductOption";
import { ProductPickStatus } from "./ProductPickStatus";
import { ProductPrice } from "./ProductPrice";
import { ProductShipping } from "./ProductShipping";
import { ProductStatus } from "./ProductStatus";

export {
  ProductCardSkeleton,
  ProductCardSkeletons,
} from "./ProductCardSkeleton";

export const ProductCard = {
  Badges: ProductBadges,
  ChannelInfo: ProductChannelInfo,
  DeliveryTracking: ProductDeliveryTracking,
  Image: ProductImage,
  Name: ProductName,
  Option: ProductOption,
  PickStatus: ProductPickStatus,
  Price: ProductPrice,
  Shipping: ProductShipping,
  Status: ProductStatus,
};
