import type { ChannelInfo } from "entities/seller-product";
import Shared from "shared/ui";
import styles from "./ProductModules.module.scss";

type Props = {
  channelInfo: ChannelInfo[];
};

const getChannelIconUrl = (channelName: string): string => {
  const generateImageUrl = (name: string) =>
    `https://pricegolf.co.kr/images/${name}.png`;

  if (channelName.includes("auction")) return generateImageUrl("auction");
  if (channelName.includes("gmarket")) return generateImageUrl("icon_gmarket");

  return generateImageUrl(`icon_${channelName}`);
};

export const ProductChannelInfo = ({ channelInfo }: Props) => {
  return (
    <div className={styles.ProductChannelInfo}>
      <Shared.HStack className="text13" gap={2}>
        <span className="font_600">연동판매</span>
        <span>({channelInfo.length})</span>
      </Shared.HStack>

      <Shared.HStack className={styles.iconList}>
        {channelInfo.map(
          ({ coopKrName, coopName, coopUrl }) =>
            coopUrl.includes("http") && (
              <a key={coopName} href={coopUrl} target="_blank" rel="noreferrer">
                <div className={styles.image}>
                  <Shared.Image
                    src={getChannelIconUrl(coopName)}
                    alt={`${coopKrName}로 이동`}
                    width={14}
                  />
                </div>
              </a>
            )
        )}
      </Shared.HStack>
    </div>
  );
};
