import classNames from "classnames";
import type { ReactNode } from "react";
import styles from "./Card.module.scss";
import type { CardHeaderProps } from "./types";

interface TitleProps {
  title: ReactNode;
  subtitle?: string;
}

const Title = ({ title, subtitle }: TitleProps) => {
  return (
    <div className={styles.Title}>
      <span className={styles.title}>{title}</span>
      {subtitle && (
        <>
          <hr />
          <span className={styles.subtitle}>{subtitle}</span>
        </>
      )}
    </div>
  );
};

export const CardHeader = ({
  className,
  children,
  title,
  ...rest
}: CardHeaderProps) => {
  return (
    <header className={classNames(styles.CardHeader, className)} {...rest}>
      {title && <h4 className={styles.title}>{title}</h4>}
      {children}
    </header>
  );
};

CardHeader.Title = Title;
