import { LegacyContainedBtn } from "@components/zLegacyAtom/Buttons/ContainedBtn";
import { LegacyCheckBox } from "@components/zLegacyAtom/Inputs/CheckBox/CheckBox";
import { PostInput } from "@components/zLegacyAtom/Inputs/PostInput/PostInput";
import { PopupDefaultHeader } from "@components/zLegacyLayouts/Headers/PopupDefaultHeader";
import { TitleHeader } from "@components/zLegacyLayouts/Headers/TitleHeader";
import { PopUpView } from "@components/zLegacyLayouts/PopUp/PopUpView";
import { Select } from "@components/zLegacyLayouts/Select/Select";
import { BottomBtnWrap } from "@components/zLegacyLayouts/Wraps/BottomBtnWrap";
import { useAppSelector } from "app/appStore";
import { CheckboxAddrCard } from "modules/Checkout/CheckboxAddrCard.module";
import qs from "qs";
import React, { useEffect } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CallApi from "shared/api/CallApi";
import type { UserInfo } from "shared/types/User.types";
import Swal from "sweetalert2";
import "./style/CheckoutAddr.scss";

// 배송지 선택
export const CheckoutSelectAddr = () => {
  return (
    <div id="CheckoutSelectAddr">
      <TitleHeader title="배송지 선택" type="X" />
      <div className="wrapper">
        <Link to="/checkout/address/register">
          <button className="add_addr">+ 배송지 추가하기</button>
        </Link>
        <CheckboxAddrCard data={ADDRESS_ITEMS} />
        <BottomBtnWrap btn1={<LegacyContainedBtn text="주소 선택" />} />
      </div>
    </div>
  );
};

// 배송지 추가(내 배송지)
export const CheckoutRegisterAddr = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setFocus,
    setValue,
    getValues,
    watch,
  } = useForm<UserInfo>({
    mode: "onChange",
  });
  return (
    <div id="CheckoutRegisterAddr">
      <TitleHeader title="내 배송지" type="X" />
      <div className="wrapper">
        <div className="input_wrapper">
          <span>배송지명</span>
          <input type="text" placeholder="배송지 이름 입력" />
        </div>
        <div className="input_wrapper">
          <span>받는사람</span>
          <input type="text" placeholder="이름" />
          <input type="text" placeholder="연락처" />
        </div>
        <div className="input_wrapper">
          <PostInput
            register={register}
            errors={errors}
            isSubmitted={isSubmitted}
            setValue={setValue}
          />
        </div>
        <div className="input_wrapper">
          <span>배송요청사항</span>
          <Select default="배송요청사항(선택)">
            <option>배송</option>
            <option>배송</option>
            <option>배송</option>
          </Select>
        </div>
        <LegacyCheckBox label="기본배송지로 설정" onChange={() => undefined} />
        <BottomBtnWrap btn1={<LegacyContainedBtn text="추가하기" />} />
      </div>
    </div>
  );
};

interface InputTypes {
  name: string;
  phone: string;
  postcode: string;
  addressLegacy: string; // 도로명
  addressRoad: string;
  addressDetail: string;
  comment?: string;
}

// 배송지 수정
export const EditDeliveryAddr = ({
  setOpenPopUpPage,
}: {
  setOpenPopUpPage?: React.Dispatch<
    React.SetStateAction<{
      type: string;
      isOpen: boolean;
    }>
  >;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setFocus,
    setValue,
    getValues,
    watch,
  } = useForm<InputTypes>({
    mode: "onChange",
  });

  const navigate = useNavigate();
  const qString = qs.parse(window.location.search.slice(1));
  const { navigationPath, isLoggedIn } = useAppSelector(state => state.user);

  const abortController = new AbortController();
  const getAddr = async () => {
    const res = await CallApi.request(
      `/api/v1/orders/purchases/${qString.orderId}/get-delivery-address`,
      "GET",
      null,
      null,
      abortController.signal
    );
    if (res) {
      setValue("name", res.result.name);
      setValue("phone", res.result.phone);
      setValue("addressLegacy", res.result.address1);
      setValue("addressDetail", res.result.address2);
      setValue("addressRoad", res.result.address1_2);
      setValue("postcode", res.result.zip);
      setValue("comment", res.result.comment);
    }
  };
  useEffect(() => {
    getAddr();
    return () => abortController.abort();
  }, []);

  const onSubmit: SubmitHandler<InputTypes> = async data => {
    const res = await CallApi.request(
      `/api/v1/orders/purchases/change-address`,
      "PUT",
      null,
      {
        orderId: qString.orderId,
        zip: data.postcode,
        address1: data.addressLegacy,
        address1_2: data.addressRoad,
        address2: data.addressDetail,
        comment: data.comment,
        phone: data.phone,
        name: data.name,
      }
    );

    if (res?.responseType === "SUCCESS") {
      Swal.fire({
        text: "수정되었습니다.",
        confirmButtonText: "확인",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          if (isLoggedIn) {
            navigate(-1);
          } else {
            navigate(`${navigationPath?.from}?orderId=${qString?.orderId}`);
          }
        }
      });
    }
  };

  return (
    <PopUpView>
      <div id="EditDeliveryAddr">
        <PopupDefaultHeader title="배송지 변경" backRoute={-1} />
        <div className="wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* TODO: 2차개발 */}
            {/* {isLoggedIn && ( //회원일 때만 노출
          <div className="input_wrapper">
            <span>배송지명</span>
            <input type={"text"} placeholder="배송지 이름 입력" />
          </div>
        )} */}
            <div className="input_wrapper">
              <span>받는 사람</span>
              <input
                type="text"
                placeholder="이름"
                {...register("name", {
                  required: "받는 사람의 이름을 입력해주세요.",
                })}
              />
              {errors.name && (
                <p className="validationErrorMessage">{errors.name.message}</p>
              )}
            </div>
            <div className="input_wrapper">
              <span>휴대폰번호</span>
              <input
                type="tel"
                placeholder="휴대폰번호"
                {...register("phone", {
                  required: "휴대폰번호를 입력해 주세요.",
                  pattern: {
                    value: /^\d+$/,
                    message: "숫자만 입력해 주세요",
                  },
                  validate: v => /^(01[0-9])([0-9]{3,4})([0-9]{4}$)/.test(v),
                })}
              />
              {errors.phone?.type === "required" && (
                <p className="validationErrorMessage">{errors.phone.message}</p>
              )}
              {errors.phone?.type === "validate" && (
                <p className="validationErrorMessage">
                  유효하지 않은 휴대전화번호 입니다.
                </p>
              )}
              {errors.phone?.type === "pattern" && (
                <p className="validationErrorMessage">{errors.phone.message}</p>
              )}
            </div>

            <div className="input_wrapper">
              <PostInput
                register={register}
                errors={errors}
                isSubmitted={isSubmitted}
                setValue={setValue}
                watch={watch}
                getValues={getValues}
              />
            </div>
            <div className="input_wrapper">
              <span>배송요청사항</span>
              <input
                type="text"
                placeholder="배송요청사항"
                {...register("comment", { required: false })}
              />
              {/* TODO: 2차개발 */}
              {/* <Select default="배송요청사항(선택)">
            <option>배송</option>
            <option>배송</option>
            <option>배송</option>
          </Select> */}
            </div>
            {/* TODO: 2차개발 */}
            {/* {isLoggedIn && ( //회원일 때만 노출
          <CheckBox label={"기본배송지로 설정"} onChange={() => undefined} />
        )} */}
            <div className="btn_wrapper">
              {/* TODO: 2차개발 */}
              {/* <OutlinedBtn type="red" text={"삭제하기"} /> */}
              <LegacyContainedBtn text="수정하기" />
            </div>
          </form>
        </div>
      </div>
    </PopUpView>
  );
};

const ADDRESS_ITEMS = [
  {
    title: "우리집",
    name: "김골프",
    tel: "010-000-0000",
    addr: "서울특별시 강남구 봉은사로 321 000빌딩 503",
    request: "문 앞에 보관해주세요",
    default: true,
  },
  {
    title: "회사",
    name: "김골프",
    tel: "010-000-0000",
    addr: "서울특별시 강남구 봉은사로 321 000빌딩 503",
    request: "경비실에 보관해주세요",
    default: false,
  },
  {
    title: "친구집",
    name: "김골프",
    tel: "010-000-0000",
    addr: "서울특별시 강남구 봉은사로 321 000빌딩 503",
    request: "경비실에 보관해주세요",
    default: false,
  },
];
