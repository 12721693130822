import type { Key } from "react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "shared/lib";
import type { ArrayOrElement } from "shared/types/utils";

export const useQueryParameterUpdater = (
  baseRoute: string | undefined = undefined,
  replace = true
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateStatus = useCallback(
    (params: ArrayOrElement<{ [key: Key]: string }>) => {
      const paramList = Array.isArray(params) ? params : [params];

      const searchParams = new URLSearchParams(location.search);
      paramList
        .flatMap(param => Object.entries(param))
        .forEach(([key, _value]) => {
          const value = _value as string;
          searchParams.set(key, value);
        });

      const url = baseRoute ?? location.pathname;
      navigate(`${url}?${searchParams.toString()}`, { replace });
      scrollToTop();
    },
    [baseRoute, location.pathname, location.search, navigate, replace]
  );

  return updateStatus;
};
