import { ReactNode } from "react";
import "./Header.scss";

type Props = {
  className?: string;
  children: ReactNode;
  id?: string;
};

export const Header = ({ className = "", id, children }: Props) => {
  return (
    <header className={`global-header ${className}`} id={id}>
      {children}
    </header>
  );
};
