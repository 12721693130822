import React from "react";
import { LegacyCheckBox } from "../../zLegacyAtom/Inputs/CheckBox/CheckBox";
import "./Card.scss";

export const RoundedCard = React.forwardRef((props: any, ref: any) => {
  return (
    <div
      className={
        props.className
          ? `roundedCard rounded ${props.className}`
          : `roundedCard rounded`
      }
      ref={ref}
    >
      {props.children}
    </div>
  );
});

export const SquareCard = (props: any) => {
  return <div id="SquareCard">{props.children}</div>;
};

export const ColumnCard = React.forwardRef((props: any, ref: any) => {
  const checkbox = ["add"].includes(props.checkbox) ? props.checkbox : "";

  return (
    <div
      id="ColumnCard"
      onClick={
        props?.goToProductDetails
          ? () => props.goToProductDetails()
          : () => undefined
      }
      ref={ref}
    >
      {checkbox === "checkbox" ? (
        <LegacyCheckBox
          label={props.label}
          onChange={props.onChange}
          name={props.name}
          checked={props.checked}
          id={props.id}
        />
      ) : null}
      {props.children}
    </div>
  );
});

// import React from "react";
// import { CheckBox } from "../../Atom/Inputs/CheckBox/CheckBox";
// import "./Card.scss";

// export const RoundedCard = React.forwardRef((props: any, ref: any) => {
//   return (
//     <div className="roundedCard rounded" ref={ref} >
//       {props.children}
//     </div>
//   );
// });

// export const SquareCard = (props: any) => {
//   return <div id="SquareCard">{props.children}</div>;
// };

// export const ColumnCard = (props: any) => {
//   const checkbox = ["add"].includes(props.checkbox) ? props.checkbox : "";

//   return (
//     <div id="ColumnCard" onClick={()=>props.goToProductDetails()}>
//       {checkbox === "checkbox" ? (
//         <CheckBox
//           label={props.label}
//           onChange={props.onChange}
//           name={props.name}
//           checked={props.checked}
//           id={props.id}
//         />
//       ) : null}
//       {props.children}
//     </div>
//   );
// };
