import React from "react";
import "./Spinner.scss";
import { RotatingLines } from "react-loader-spinner";
export const Spinner = () => {
  return (
    <div id="Spinner">
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="40"
        visible={true}
      />
    </div>
  );
};
