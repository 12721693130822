export const CATEGORY_FILTER = [
  "전체",
  "드라이버",
  "아이언",
  "풀세트",
  "페어웨이우드",
  "유틸리티",
  "웨지/치퍼",
  "퍼터",
  "클럽기타",
  "피팅용품",
  "골프의류",
  "스크린장비",
];

export const INFORMATION = [
  "프라이스",
  "골프",
  "골프용품",
  "프라이스골프",
  "벤투스",
  "강인혜",
  "프론트엔드",
];

export const CATEGORY_SERVICE_LIST_NAMES = [
  "상품등록",
  "직매입 견적의뢰",
  "실시간상품",
  "가격인하상품",
  "중고시세표",
  "파워존",
  "신상품샵",
  "고객센터",
  "판매가이드",
  "출석체크/쿠폰",
  "회사소개",
];

export const RADIO_AS_LIST = [
  { text: "불가능", value: "0" },
  { text: "가능", value: "1" },
];

export const RADIO_ORIGIN_LIST = [
  { text: "국산", value: "0" },
  { text: "수입", value: "1" },
];

export const RADIO_GENUINE_LIST = [
  { text: "아시안스펙 정품", authenticType: "1" },
  { text: "아시안스펙 직수(병행)", authenticType: "2" },
  { text: "미국스펙 정품", authenticType: "3" },
  { text: "미국스펙 직수(병행)", authenticType: "4" },
  { text: "피팅클럽", authenticType: "5" },
];

export const OPTION_SELECT_CHECKBOX = [
  "샤프트",
  "클럽번호",
  "클럽길이",
  "헤드구분",
  "헤드각도",
  "색상",
];

export const RADIO_OPTION_LIST = [
  { text: "없음", value: false },
  { text: "사용", value: true },
];

export const RADIO_STATUS_LIST = ["중고상품", "신상품"];

export const RADIO_OPTION_VERSION_LIST = [
  {
    text: "추천옵션",
    value: "NEW",
  },
  { text: "구버전옵션", value: "OLD" },
];

export const WEAR_CONDITION = [
  { text: "95%", value: 95 },
  { text: "90%", value: 90 },
  { text: "85%", value: 85 },
  { text: "80%", value: 80 },
  { text: "75%", value: 75 },
  { text: "70%", value: 70 },
  { text: "65%", value: 65 },
  { text: "60%", value: 60 },
  { text: "55%", value: 55 },
  { text: "50%", value: 50 },
];

export const NOTICEINFO_LIST = [
  {
    noticeCategoryCode: 1, // 그 외 나머지 카테고리
    noticeValueList: [
      { noticeValueCode: 35, notiveValueCodeText: "품명 및 모델명" },
      {
        noticeValueCode: 36,
        notiveValueCodeText: "크기, 중량",
        exampleText:
          "예시) 드라이버 헤드(460cc), 샤프트길이(46인치), 중략 약296g",
      },
      {
        noticeValueCode: 37,
        notiveValueCodeText: "색상",
        exampleText: "예시) 블랙 외(상세 이미지 설명참조)",
      },
      {
        noticeValueCode: 38,
        notiveValueCodeText: "재질",
        exampleText: "예시) 헤드:티타늄, 샤프트:카본, 아이언헤드:단조, 스틸",
      },
      {
        noticeValueCode: 39,
        notiveValueCodeText: "제품 구성",
        exampleText: "예시) 드라이버, 헤드커버, 렌치",
      },
      {
        noticeValueCode: 40,
        notiveValueCodeText: "출시 연월",
        exampleText: "예시) 2020년/2018~2020년",
      },
      {
        noticeValueCode: 41,
        notiveValueCodeText: "제조국(수입자)",
        exampleText: "예시) 캘러웨이",
      },
      { noticeValueCode: 42, notiveValueCodeText: "제조국" },
      {
        noticeValueCode: 43,
        notiveValueCodeText: "상품별 세부 사양",
        exampleText: "예시) 상세페이지 스펙 참조",
      },
      {
        noticeValueCode: 44,
        notiveValueCodeText: "품질보증기준",
        exampleText:
          "예시) 공정거래위원회 고시(소비자분쟁해결기준)의 기준을 따름",
      },
      { noticeValueCode: 45, notiveValueCodeText: "A/S 책임자와 전화번호" },
      {
        noticeValueCode: 46,
        notiveValueCodeText: "KC 인증번호",
        exampleText: `전기를 사용해야하는 모든 제품과 그 제품에 사용되는 호환용 모든 제품. 예시) 비 해당 시 "상세페이지참조"로 표기 바랍니다.`,
      },
    ],
  },
  {
    noticeCategoryCode: 2, // 골프의류(2154)
    noticeValueList: [
      {
        noticeValueCode: 1,
        notiveValueCodeText: "제품소재",
        exampleText: "예시) 의류재질, 원단",
      },
      { noticeValueCode: 2, notiveValueCodeText: "색상" },
      {
        noticeValueCode: 3,
        notiveValueCodeText: "치수",
        exampleText: "예시) 사이즈S(90), M(95), L(100)",
      },
      { noticeValueCode: 4, notiveValueCodeText: "제조자(수입자)" },
      { noticeValueCode: 5, notiveValueCodeText: "제조국" },
      {
        noticeValueCode: 6,
        notiveValueCodeText: "세탁방법 및 취급시 주의사항",
        exampleText: "예시) 울세탁 금지, 드라이크리닝 세탁",
      },
      {
        noticeValueCode: 7,
        notiveValueCodeText: "제조 연월",
        exampleText: "예시) 2005년~2010년, 2019년도",
      },
      {
        noticeValueCode: 8,
        notiveValueCodeText: "품질보증기준",
        exampleText:
          "예시) 공정거래위원회 고시(소비자분쟁해결기준)의 기준을 따름",
      },
      { noticeValueCode: 9, notiveValueCodeText: "A/S 책임자와 전화번호" },
    ],
  },
  {
    noticeCategoryCode: 3, // 골프화
    noticeValueList: [
      {
        noticeValueCode: 10,
        notiveValueCodeText: "제품의 주소재",
        exampleText: "예시) 갑피:합성가죽/안감:나일론100%/창:특수배합 합성고무",
      },
      { noticeValueCode: 11, notiveValueCodeText: "색상" },
      {
        noticeValueCode: 12,
        notiveValueCodeText: "치수",
        exampleText: "예시) 사이즈 245mm ~ 275mm",
      },
      { noticeValueCode: 13, notiveValueCodeText: "제조자(수입자)" },
      { noticeValueCode: 14, notiveValueCodeText: "제조국" },
      {
        noticeValueCode: 15,
        notiveValueCodeText: "취급시 주의사항",
        exampleText: "예시) 오염주의",
      },
      {
        noticeValueCode: 16,
        notiveValueCodeText: "품질보증기준",
        exampleText:
          "예시) 공정거래위원회 고시(소비자분쟁해결기준)의 기준을 따름",
      },
      { noticeValueCode: 17, notiveValueCodeText: "A/S 책임자와 전화번호" },
    ],
  },
  {
    noticeCategoryCode: 4, // 골프벡(2153r03)
    noticeValueList: [
      {
        noticeValueCode: 18,
        notiveValueCodeText: "종류",
        exampleText: "예시) 캐디백 / 스탠드백 / 보스톤백",
      },
      {
        noticeValueCode: 19,
        notiveValueCodeText: "소재",
        exampleText: "예시) 인조가죽, 폴리에스테르",
      },
      { noticeValueCode: 20, notiveValueCodeText: "색상" },
      {
        noticeValueCode: 21,
        notiveValueCodeText: "크기",
        exampleText: "예시) 입그 9인치(24.13cm), 무게 2.8kg",
      },
      {
        noticeValueCode: 22,
        notiveValueCodeText: "제조국(수입자)",
        exampleText: "예시) 타이틀리스트",
      },
      {
        noticeValueCode: 23,
        notiveValueCodeText: "제조국",
        exampleText: "예시) 중국",
      },
      {
        noticeValueCode: 24,
        notiveValueCodeText: "취급시 주의사항",
        exampleText: "예시) 오염주의",
      },
      {
        noticeValueCode: 25,
        notiveValueCodeText: "품질보증기준",
        exampleText:
          "예시) 공정거래위원회 고시(소비자분쟁해결기준)의 기준을 따름",
      },
      { noticeValueCode: 26, notiveValueCodeText: "A/S 책임자와 전화번호" },
    ],
  },
  {
    noticeCategoryCode: 5, // 골프모자(2153r07), 벨트/양말/썬글라스(2153r08)
    noticeValueList: [
      {
        noticeValueCode: 27,
        notiveValueCodeText: "종류",
        exampleText: "예시) 모자, 벨트, 양말",
      },
      {
        noticeValueCode: 28,
        notiveValueCodeText: "소재",
        exampleText: "예시) 모자 (폴리우레탄, 나일론)",
      },
      {
        noticeValueCode: 29,
        notiveValueCodeText: "치수",
        exampleText: "예시) FREE(사이즈 조절 가능)",
      },
      { noticeValueCode: 30, notiveValueCodeText: "제조국(수입자)" },
      { noticeValueCode: 31, notiveValueCodeText: "제조국" },
      {
        noticeValueCode: 32,
        notiveValueCodeText: "취급시 주의사항",
        exampleText: "예시) 오염주의",
      },
      {
        noticeValueCode: 33,
        notiveValueCodeText: "품질보증기준",
        exampleText:
          "예시) 공정거래위원회 고시(소비자분쟁해결기준)의 기준을 따름",
      },
      { noticeValueCode: 34, notiveValueCodeText: "A/S 책임자와 전화번호" },
    ],
  },
];

export const CATEGORY_OPTION_LIST = [
  { value: "0", name: "카테고리 전체" },
  { value: "1", name: "드라이버" },
  { value: "2032", name: "아이언" },
  { value: "2033", name: "풀세트" },
  { value: "2034", name: "페어웨이우드" },
  { value: "2035", name: "유틸리티" },
  { value: "2036", name: "웨지/치퍼" },
  { value: "2037", name: "퍼터" },
  { value: "2152", name: "클럽기타" },
];

export const BRAND_OPTION_LIST = [
  { value: "ALL", name: "전체" },
  { value: "Callaway", name: "Callaway" },
  { value: "HONMA", name: "HONMA" },
  { value: "Maruman", name: "Maruman" },
  { value: "Mizuno", name: "Mizuno" },
  { value: "ODYSSEY", name: "ODYSSEY" },
  { value: "PING", name: "PING" },
  { value: "PRGR", name: "PRGR" },
  { value: "ScottyCameron", name: "ScottyCameron" },
  { value: "SRIXON", name: "SRIXON" },
  { value: "TaylorMade", name: "TaylorMade" },
];

// export const CHIPS_TITLE = [
//   "아이언",
//   "드라이버",
//   "페어웨이우드",
//   "유틸리티",
//   "웨지/치퍼",
//   "퍼터",
// ];

export const RADIO_TEXT = [
  "아시안스펙정품",
  "아시안스펙직수(병행)",
  "미국스펙정품",
  "미국스펙직수(병행)",
  "피팅클럽",
];

export const CS_SECTION = [
  "공지사항",
  "불건전거래신고",
  "가품신고",
  "버그에러신고",
];

export const ABOUT_US = [
  {
    title: "신뢰와 공정",
    text: "프라이스골프는 판매자와 구매자간의 중개서비스로써 공정한 거래 및 엄격한 업체선정, 판매상품 관리를 통해 판매자와 고객간의 신뢰도를 높이는데 노력하고 있습니다.",
  },
  {
    title: "고객우선",
    text: "프라이스골프는 고객의 만족을 우선시 하며, 고객이 보다 편리하고 빠른 에스크로서비스가 될 수 있도록 솔루션을 구축, 관리하고 있습니다. 언제나 나은 서비스로 고객의 만족을 실현하며 고객의 의견을 적극 수용, 반영하여 더욱 발전하겠습니다.",
  },
  {
    title: "판매자 친화",
    text: "본사는 입점고객(판매자)의 편의를 도모하여 보다 빠르고 쉽게 상품을 판매할 수 있는 시스템을 구축하였으며, 초보판매자들도 쉽게 등록, 판매할 수 있는 판매자 친화 시스템을 구축하는데 노력하고 있습니다.",
  },
];

export const HISTORY_ITEMS = [
  {
    year: 2023,
    list: [
      {
        month: "04",
        text: ["해외직배송 중고상품 10만개 연동"],
      },
      { month: "05", text: ["SK스토아 API 연동 제휴"] },
    ],
  },
  {
    year: 2022,
    list: [
      {
        month: "01",
        text: ["골핑 API 연동 제휴"],
      },
      { month: "03", text: ["네이버페이 API 연동 제휴"] },
      {
        month: "06",
        text: ["카카오쇼핑 API 연동 제휴"],
      },
      {
        month: "09",
        text: ["티몬 API 연동 제휴"],
      },
      {
        month: "12",
        text: ["현대홈쇼핑 API 연동 제휴", "판매 중 상품 수 10만개 돌파"],
      },
    ],
  },
  {
    year: 2021,
    list: [
      {
        month: "02",
        text: ["롯데홈쇼핑 API 연동 제휴", "AK몰 API 연동 제휴"],
      },
      { month: "03", text: ["신세계 API 연동 제휴"] },
      {
        month: "04",
        text: ["하프클럽 API 연동 제휴", "CJ오쇼핑 API 연동 제휴"],
      },
      {
        month: "05",
        text: ["지에스샵 API 연동 제휴", "신세계 오픈마켓 API 연동 제휴"],
      },
      {
        month: "07",
        text: ["번개장터 API 연동 제휴"],
      },
      {
        month: "09",
        text: ["무신사 API 연동 제휴"],
      },
      {
        month: "12",
        text: ["하이마트 API 연동 제휴"],
      },
    ],
  },
  {
    year: 2020,
    list: [
      { month: "02", text: ["네이버(스마트스토어) API 연동 제휴"] },
      { month: "03", text: ["쿠팡 API 연동 제휴"] },
      {
        month: "05",
        text: ["위메프 API 연동 제휴"],
      },
      {
        month: "07",
        text: ["홈앤쇼핑 API 연동 제휴"],
      },
      {
        month: "08",
        text: ["롯데ON API 연동 제휴"],
      },
      {
        month: "10",
        text: [
          "누적상품수: 180만 개",
          " 평균 거래액: 10억원",
          "Web/APP 회원수: 21만/10만",
        ],
      },
    ],
  },
  {
    year: 2018,
    list: [
      { month: "01", text: ["주식회사 에스브릿지(법인전환)"] },
      { month: "10", text: ["벤처기업등록 <br/> (기술보증기금)"] },
    ],
  },
  {
    year: 2017,
    list: [
      { month: "04", text: ["11번가 API 연동"] },
      { month: "06", text: ["G마켓 API 연동"] },
      { month: "07", text: ["상표.서비스표 등록(특허청)"] },
    ],
  },
  {
    year: 2016,
    list: [{ month: "06", text: ["프라이스골프 APP 오픈"] }],
  },
  {
    year: 2015,
    list: [{ month: "05", text: ["옥션 API 연동 제휴"] }],
  },
  {
    year: 2014,
    list: [{ month: "09", text: ["인터파크 API 연동 제휴"] }],
  },
  {
    year: 2013,
    list: [
      { month: "06", text: ["프라이스골프 오픈마켓 <br/> 사이트 리뉴얼"] },
    ],
  },
  {
    year: 2012,
    list: [
      {
        month: "06",
        text: ["프라이스골프 오픈마켓 <br/> 등록상품 50만개 달성"],
      },
      {
        month: "10",
        text: ["전국 39개 <br/> 가맹/특약점 개설"],
      },
    ],
  },
  {
    year: 2011,
    list: [
      {
        month: "10",
        text: ["프라이스골프 회원 <br/> 10만 명 달성"],
      },
    ],
  },
  {
    year: 2010,
    list: [
      {
        month: "09",
        text: ["DAUM 쇼핑하우 <br/> 중고 에스크로마켓 제휴"],
      },
    ],
  },
  {
    year: 2009,
    list: [
      {
        month: "10",
        text: ["11번가 오픈마켓 <br/> 중고 에스크로마켓 제휴"],
      },
      {
        month: "12",
        text: ["가맹/특약 사업부 신설"],
      },
    ],
  },
  {
    year: 2007,
    list: [
      {
        month: "03",
        text: ["중고골프채 시세표 오픈 <br/> (국내 최초 적용)"],
      },
    ],
  },
  {
    year: 2005,
    list: [
      {
        month: "10",
        text: ["GOLFSKY <br/> 에스크로마켓 제휴"],
      },
    ],
  },
  {
    year: 2004,
    list: [
      {
        month: "11",
        text: ["프라이스골프 <br/> 오픈마켓 서비스 OPEN"],
      },
    ],
  },
  {
    year: 2003,
    list: [
      {
        month: "11",
        text: ["프라이스골프 창립"],
      },
    ],
  },
];

export const RETURN_PROCEDURE_INFO = [
  `- 반품절차안내
· 판매자와 연락하여 반품 주소지를 안내받고, 반품 배송비에 대해 협의합니다.(협의에 문제가 있을 경우 프라이스골프 고객센터로 문의 주시기 바랍니다.)
협의된 내용에 따라 상품을 판매자에게 반송합니다. (구매자 직접 발송)
· 아래 입력란에 발품 사유, 송장 번호를 기입하여 반품요청을 진행합니다.
· 판매자가 상품을 회수 우 반품을 승인하면 환불 절차가 진행됩니다.

- 반품이 불가한 경우
· 상품을 배송받은 날로부터 6일 이상 경과한 경우
· 구매자의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우
· 골프채를 시타하였을 경우
· 주문 후 제작에 들어가는 주문제작 상품의 경우

- 반품 배송비 처리 규정
반품시에는 반품의 원인을 제공한 측에서 왕복 운송비용을 부담하여야 합니다.

- 구매자 사유(단순변심 등)로 인한 반품일 경우
· 무료 배송 상품의 경우 무료배송은 최초 배송비를 판매자가 지불한 경우입니다.
· 판매자가 지불한 최초 배송비를 문의하여 택배상자에 현금으로 동봉한 뒤 반송 과정에서 소요되는 배송비를 택배사에 지불하여 반송합니다.
· 최초 배송비를 구매자가 선결제하였을 경우 판매자가 지불한 최초 배송비를 문의하여 택배상자에 현금으로 동봉한 뒤 반송 과정에서 소요되는 배송비를 택배사에 지불하여 반송합니다.
· 프라이스골프에서는 구매자가 결제한 금액이 전액 환불됩니다.
· 최초 배송비를 구매자가 착불로 지불하였을 경우, 반송 과정에서 소요되는 배송비를 택배사에 지불하여 반송합니다.

- 판매자 사유 (상품정보오기재 등) 로 인한 반품일 경우
· 최초 배송비를 착불로 택배기사에게 지불했다면, 해당 금액을 판매자에게 청구합니다.

반송시에는 착불로 발송을 하거나, 판매자가 요청시 선불로 발송하되 해당 금액을 판매자에게 청구합니다. 구매시 결제한 금액은 전액 환불됩니다.
`,
];

export const ORDER_CANCEL_PROCEDURE_INFO = [
  `- 구매취소 주의사항
· 정당하지 않은 구매취소 사유는 판/구매활동에 제한을 받을 수 있습니다.
· 판매자가 이미 상품을 발송하였을 경우, 상품 수령 후 반품진행을 하셔야합니다.
- 환불은 아래와 같이 진행되니 참고바랍니다.
· 카드결제 : 영업일 기준 2~3일 내 카드결제가 취소됩니다.
· 무통장입금 : 영업일 기준 다음날 환불됩니다.`,
];

export const RETURN_CAUTIONS = [
  `- 프라이스골프 문의라고 표시된 항목은 프라이스골프 고객센터로 문의바랍니다.
- 반품방법 중 판매자 수거요청은 반드시 판매자님이 직접 상품회수 요청을 하셔야 합니다.
- 일부 제휴업체는 판매자 책임 귀책사유라도 반품비 결제방법이 노출될 수 있습니다.
- 반품배송비는 매주 목요일 일괄적으로 지급합니다.
`,
];

export const KEY_TERMS_GUIDE = [
  `1.반품방법
  - 쇼핑몰 지정택배(연동) : 쇼핑몰 제휴업체의 지정택배로 반품합니다. 상품은 자동수거됩니다.
  - 판매자 지정택배(연동) : 판매자 지정택배사로 반품합니다. 상품은 자동수거됩니다.
  - 판매자 수거요청 : 판매자가 직접 택배사로 상품수거 요청을 해야 합니다.
  - 구매자 직접발송 : 구매자가 직접 반품합니다.
  `,
];

export const TERMS = {
  data: [
    {
      title: "상품등록약관",
      type: "regProducts",
      text: `프라이스골프 이용약관

제1장 총칙

제1조 [목적]
이 약관은 ㈜에스브릿지(이하 “회사“)가 운영하는 사이트(http://pricegolf.co.kr 및 모바일 어플리케이션)에서 제공하는 전자상거래 관련 서비스(이하 ”서비스“)를 이용함에 있어 상품 또는 용역을 거래하는 자 간의 권리, 의무 등 기타 필요사항, 회원과 회사간의 권리, 의무, 책임사항 및 회원의 서비스 이용절차 등에 관한 사항을 규정함을 목적으로 합니다.

제2조 [용어의 정의]
① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. 사이트: 자유로운 상품 또는 용역(이하 "상품")의 거래를 위하여 회사가 제공하는 실시간 전자상거래 시스템과 그 운영을 위한 웹사이트 및 모바일 어플리케이션을 말합니다.
2. 서비스: 회사가 사이트를 통하여 이용자들에게 제공하는 서비스 및 관련 부가서비스 일체를 말합니다.
3. 이용자: 이 약관에 따라 회사가 제공하는 서비스를 받는 회원과 비회원을 말합니다.
4. 회원: 회사가 정한 가입절차에 따라 회원등록을 한 개인 또는 사업자로서, 회사의 정보를 지속적으로 제공받으며 회사가 제공한 서비스를 이용할 수 있는 자를 말합니다. 회원은 다음과 같이 일반회원과 인증회원으로 구분됩니다.
가. 일반회원(구매고객): 사이트에서 상품구매 및 구매와 관련하여 제공되는 서비스를 이용할 수 있는 회원을 말합니다.
나. 인증회원(구매판매고객): 사이트에서 상품 구매 및 관련 서비스와 판매이용약관상의 서비스를 이용할 수 있는 회원을 말합니다.
5. 비회원: 회원에 가입하지 않고(SNS 계정을 통해 로그인하고 본인인증을 거치지 않은 경우 포함) 회사가 제공하는 서비스를 이용하는 자를 말합니다.
6. 아이디(ID): 회원의 식별과 회원의 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 회사에 등록된 영문, 숫자 또는 영문과 숫자의 조합을 말합니다.
7. 비밀번호(Password): 회원 인지를 확인하고 비밀을 보호하기 위하여 회원이 스스로가 설정해 회사에 등록한 영문과 숫자, 특수문자의 조합을 말합니다.
8. 운영자: 회사에서 제공하는 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 자를 말합니다.
9. 구매자: 회사에 등록된 판매자의 상품을 구매하는 자를 말하며, 일반회원과 인증회원, 비회원에게 모두 구매자격이 부여 됩니다.
10. 판매자: 상품을 판매할 목적으로 회사가 제공하는 상품광고양식에 맞추어 상품을 등록한 회원을 말하며, 인증회원에게 판매의 권한이 주어집니다.
11. 회원등급: 회사가 제공하는 서비스를 이용한 결과에 따라 일정한 기준에 따라 회사가 회원에게 부여하는 등급을 말합니다.
12. 상품평: 상품 구매 후 해당 상품에 대한 종합적인 평가를 기록한 글, 이미지, 영상물 등의 게재물을 말합니다.
13. 구매확정기간: 구매자가 거래에 대하여 구매 종료(구매확정, 반품, 교환)의 의사표시를 해야 하는 기간으로, 물품수령일로부터 7일이 되는 날(물품수령일을 확인할 수 없을 때에는 발송완료일로부터 21일이 되는 날을 물품수령일로 간주하고 그로부터 7일이 되는날)까지를 말합니다.
※제휴사 통해 판매가 되었을 시 제휴사 기준을 우선시합니다.
14. 본인확인: 휴대전화 인증을 통하여 회원이 본인인지 여부를 확인하거나 사업자 회원의 상호와 사업자 등록번호의 일치 여부를 확인하고 제8조에서 정한 기재항목을 확인하는 것을 의미합니다.
15. 영업일: 회사가 정상적으로 서비스를 제공한 날로서 토요일, 일요일 및 법정 공휴일을 제외한 날을 말합니다.
16. 결제대금 보호서비스: 회사가 구매자가 결제한 결제대금을 보호하기 위하여 일정기간 결제대금을 예치하는 서비스를 말합니다.
17. 안전거래센터: 서비스 이용 중 거래의 안전과 관련하여 발생할 수 있는 문제를 조정하기 위한 기구를 말합니다.
18. 분쟁조정센터: 프라이스골프를 통한 거래에서 분쟁이 발생하면 판매자와 구매자가 자율적으로 해결하는 것이 원칙이지만, 분쟁의 합리적이고 원활한 조정을 위하여 예외적으로 회사가 설치·운영하는 분쟁조정기구를 말합니다.
② 제1항에서 정의되지 않은 용어의 의미는 관련 법령 및 일반적인 거래관행을 따릅니다.
19. 제휴사 : 프라이스골프와 협약 된 제휴사(오픈마켓,소셜커머스,홈쇼핑사등)를 말합니다.

제3조 [약관의 게시, 효력 및 변경]
① 회사는 정의된 약관을 이용자가 알 수 있도록 회사의 서비스가 제공되는 사이트의 첫 화면에 게시합니다.
② 회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령에 위배되지 않는 범위 안에서 이 약관을 변경 할 수 있으며, 회사가 약관을 변경할 때에는 적용일자와 변경사유를 밝혀 그 적용일자 7일 전부터 사이트에 공지합니다.
③ 회사는 관련 법령의 규정 등에 따라 판매서비스 중 특정 서비스에 관한 약관(이하 "개별약관")을 별도로 제정할 수 있으며, 판매회원이 개별약관에 동의하면 개별약관은 이용계약의 일부를 구성하고, 개별약관에 이 약관과 상충하는 내용이 있으면 개별약관이 우선하여 적용됩니다.
④ 이 약관에 동의하는 것은 정기적으로 회사가 운영하는 인터넷 사이트를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원 또는 이용자의 피해에 대해 회사는 책임을 지지 않습니다.
⑤ 변경된 약관에 동의하지 않는 회원은 회원 탈퇴(이용계약의 해지)를 요청할 수 있으며, 회사가 제2항에 따라 이용자에게 약관 변경 적용일까지 거부의사를 표시하지 않을경우 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도, 이용자가 명시적으로 약관 변경에 대한 거부의사를 표시하지 않으면 회사는 이용자가 변경된 약관에 동의한 것으로 간주합니다.

제4조 [약관 외 준칙 및 관계법령과의 관계]
① 이 약관에 밝히지 않은 사항은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정과 일반 상관례를 따릅니다.
② 회사는 필요하면 특정 서비스에 적용될 사항(이하 “개별약관”)을 정하여 이를 사이트를 통하여 공지할 수 있습니다.
③ 회사는 필요하면 서비스 이용과 관련된 세부적인 내용(이하 "이용정책")을 정하여 이를 사이트를 통하여 공지할 수 있습니다.
④ 개별약관이나 이용정책의 변경이 있으면 회사는 변경 내용의 효력발생일 7일 이전에 사이트를 통하여 해당 변경 사항을 공지합니다.
⑤ 회원은 항상 개별약관이나 이용정책의 내용에 변경이 있는지 여부를 주시하여야 하며, 변경사항의 공지가 있을 때에는 이를 확인하여야 합니다.
제2장 서비스 이용계약

제5조 [이용계약의 성립]
① 회사가 제공하는 서비스에 관한 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는 자의 이용신청을 회사가 승낙함으로써 성립하며, 이때 서비스를 이용하고자 하는 자는 회사가 온라인으로 제공하는 이용 신청 양식에 따라 필요한 각각의 항목에 해당 내용을 기재한 후 이 약관에 동의한다는 의사 표시를 해야 합니다.
② 회원가입의 성립시기는 회사의 이용승낙 의사가 해당 서비스화면에 게시하거나, 이메일(E-mail) 또는 기타 회사가 정하는 방법으로 이용신청자에게 도달한 시점으로 합니다.
③ 회원가입은 개인의 경우 만 14세 이상이면 신청할 수 있으며, 사업자(개인사업자 및 법인사업자 포함)는 가입 신청일 현재 정상적인 영업활동이 가능해야 신청할 수 있습니다.
④ 일반회원은 다수의 아이디(ID)를 생성하여 사용할 수 있으나, 인증회원은 본인 1인(본인확인 기준, 법인사업자와 개인사업자의 경우에는 각각 법인번호, 사업자번호 기준)당 1개 아이디(ID)를 사용하는 것을 원칙으로 합니다.
⑤ 이용계약을 종료(해지 포함)한 후 재가입하는 회원은 최종 이용할 때 사용한 아이디(ID)는 사용할 수 없으며 새로운 아이디(ID)를 사용하는 것을 원칙으로 합니다.
⑥ 인증회원이 가입신청을 할 때에는 본인확인기관을 통하여 휴대전화 인증으로 본인여부를 확인하는 방식의 본인확인절차를 진행하며, 사업자 회원은 상호와 사업자 등록번호의 일치 여부를 확인하는 방식으로 본인확인절차를 진행합니다. 또한 회사는 본인확인절차를 따를 수 없는 이용신청이 있으면 해당 신청자에게 증빙자료를 요청할 수 있습니다.
⑦ 탈퇴 후 회원 재가입, 임의 해지 등을 반복하거나 다수의 아이디(ID)로 가입하여 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등으로 경제적 이익을 편법으로 수취하고, 이 과정에서 명의를 무단으로 사용하는 등 편법과 불법 행위를 하는 회원을 차단하고자 회사는 다수의 아이디(ID)보유 회원에 대하여 최초 본인확인된 아이디(ID)에 한하여 일부 서비스 이용을 허용할 수 있으며, 회원이 탈퇴한 후 1개월(탈퇴시 회원이 판매회원의 지위도 동시에 가진 상태인 경우에는 2개월)이 지나지 않으면 회원의 서비스 가입신청을 거부할 수 있습니다.

제6조 [개인정보의 변경, 보호]
① 회원은 이용신청 시 기재한 사항이 변경되었을 경우에는 상당한 기간 이내에 회원정보 수정 등의 방법으로 그 변경사항을 수정하여야 합니다. 단, 이름, 아이디(ID) 등 변경할 수 없는 사항은 수정할 수 없습니다.
② 수정하지 않은 정보로 말미암아 발생하는 손해는 해당 회원이 부담하며, 회사는 이에 대해 아무런 책임을 지지 않습니다.
③ 회사는 이용자의 정보를 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다. 다만 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
④ 회사는 관련법령이 정하는 바에 따라서 이용자의 개인정보를 보호하기 위하여 노력하며, 이용자의 개인정보를 보호하기 위한 정책을 수립하고 개인정보보호 책임자를 지정하여 이를 게시합니다.
⑤ 이용자의 개인정보보호는 관련 법령 및 회사가 정하는 개인정보처리방침에서 정한 바를 따릅니다.
⑥ 사이트에서 제공하는 서비스를 통하여 거래가 성사 되었을 때 회사는 판매자와 구매자 간에 물품거래와 배송 등의 이행과 관련하여 필요한 범위 내에서 구매자의 개인정보를 판매자에게 제공합니다.

제7조 [서비스 이용 신청]
① 회사가 제공하는 서비스에 가입 하여 이용하고자 하는 자는 회사에서 제공한 회원가입 신청양식에 따라 필요사항을 기재하여야 합니다. 회원가입 양식은 필수 기재 항목과 선택 기재 항목으로 구분 되어 있으며 필수 기재 항목은 반드시 입력 해야 합니다.
② 회사는 이용자의 회원 가입 시 제1항 또는 제2항에 표시된 필수항목 외에 추가적인 정보를 요청할 수 있으며, 이용신청자는 그러한 추가적인 정보의 제공을 거절할 수 있습니다. 추가적인 정보는 회원에게 다양하고 유익한 정보를 제공하기 위해서만 사용되며 그 밖의 목적으로 사용할 수 없습니다.
③ 회사는 모든 회원에게 광고성 이메일(E-mail) 수신여부, 광고성 문자메시지(SMS) 수신여부, 제휴회사 및 개인정보 위탁관리회사에 대한 개인정보 제공, 이용에 대한 동의 여부를 확인할 수 있습니다.

제8조 [이용신청의 승낙, 거부, 유보]
① 회사는 이 약관 제7조에서 정한 기재항목을 사실대로 정확하게 기재하고 이 약관에 동의한 자의 가입 신청에 대하여 서비스의 이용을 승낙함을 원칙으로 합니다.
② 회사는 인증회원의 가입신청에 대해서는 법령에 따라 이용 할 수 있는 본인확인기관을 통하여 본인 여부를 확인하는 방식의 본인확인절차를 이행합니다. 회사는 본인확인절차를 이행할 수 없는 이용신청에 대해서는 신청자에게 증빙자료를 요청할 수 있습니다.
③ 회사는 다음과 같은 사유가 있으면, 이용신청에 대한 승낙을 거부할 수 있습니다.
1. 만 14세 미만의 자가 이용신청을 하는 경우
2. 상기 제 2항의 본인확인절차에서 본인이 아님이 확인된 경우
3. 이미 가입된 인증회원과 성명 및 CI(Connecting Information, 연계정보, 서비스 연계를 위해 본인확인기관에서 부여하는 개인식별정보), DI(Duplication Information, 중복가입 확인정보) 또는 사업자등록번호, 법인등록번호가 동일한 경우
4. 신청, 등록 내용에 허위, 기재누락, 오기 등이 있는 경우
5. 이용계약이 해지된 날로부터 1개월 이내에 재이용신청을 하는 경우
6. 회사로부터 회원자격정지 조치 등을 받은 회원이 그 조치 기간에 이용계약을 임의 해지하고 재이용신청을 하는 경우(개인구매회원에게 재이용이 허락되더라도, 서비스 이용과정에서 본인확인 후 회원자격정지 조치를 받았던 사실이 확인되면 해당 ID의 이용계약이 해지될 수 있음)
7. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우
8. 서비스 이용약관의 적용을 받는 자가 해당 약관을 위반한 경우
④ 회사는 다음과 같은 사유가 있으면, 이용신청에 대한 승낙을 유보할 수 있습니다. 이 때, 회사는 이용신청자에게 승낙유보의 사유, 승낙 가능 시기 또는 승낙에 필요한 추가요청정보나 자료 등 승낙유보와 관련된 사항을 해당 서비스 화면에 게시하거나 이메일(E-mail)로 통지합니다.
1. 설비에 여유가 없는 경우
2. 기술상 지장이 있는 경우
3. 회사의 본인확인절차에서 본인 여부가 확인되지 않는 경우
4. 기타 회사가 합리적인 판단으로 의하여 필요하다고 인정하는 경우

제9조 [이용계약의 종료]
① 회원은 다음 각호에서 정한 바에 따라 이용계약을 해지할 수 있습니다.
1. 회원은 언제든지 회사에 해지의 의사를 통지하여 이용계약을 해지할 수 있습니다. 단, 해지의사를 통지하기 전에 현재 진행 중인 모든 상품의 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 또한, 회원이 회사에 대한 채무를 전부 이행하지 않으면 회원이 해당 채무를 전부 이행할 때까지 회사는 회원의 이용계약 해지를 제한할 수 있으며, 회원이 이 약관과 관계 법령 등을 위반하여 이용이 정지된 경우 회사가 재발방지를 위하여 회원의 이용계약 해지를 제한할 수 있습니다.
2. 회사가 해지할 때에는 회사는 회원에게 이메일(E-mail), 전화, 팩스, 기타의 방법으로 해지 사유를 밝혀 해지 의사를 통지합니다. 거래의 안전을 위하여 회사는 동일인 소유로 확인되는 회원 아이디(ID)에 대하여 일괄 해지 의사를 통지할 수 있습니다. 이용계약은 회사의 해지의사를 회원에게 통지한 시점에 종료됩니다.
3. 회원의 의사로 해지한 후 회원 재가입, 임의 해지 등을 반복적으로 행하여 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등 경제적 이익을 편법으로 수취하거나 이 과정에서 명의를 무단으로 사용하는 등 편법과 불법행위를 하는 것을 차단하고자 회사는 회원에게 회원 탈퇴한 날로부터 6개월이 지나야 재가입 할 수 있게 하고 있으며 회원은 이 약관이 정하는 회원가입절차와 관련 조항에 따라 회사에 통지된 재이용 의사를 회사로부터 승낙 받은 이후에 서비스를 재이용할 수 있습니다.
4. 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등의 경제적 이익을 편법으로 수취하거나 이 과정에서 명의를 무단으로 사용하는 등 편법과 불법 행위를 하는 것을 차단하고자 회원탈퇴 후 1개월(탈퇴시 회원이 판매회원의 지위도 동시에 가진 상태인 경우에는 2개월) 동안 해당 회원의 아이디, 성명, CI, DI , 탈퇴일자, 탈퇴사유 등 개인정보를 보관합니다.
② 회사는 다음 각호에서 정한 바에 따라 이용계약을 해지할 수 있습니다.
1. 회사는 회원에게 다음 각목의 1에 해당하는 사유가 발생하거나 확인되면 이용계약을 해지할 수 있습니다.
가. 다른 회원·회사(직원·상담원 포함)의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우
나. 서비스의 원활한 진행을 방해하거나 방해할 우려가 있는 아래의 행위 등을 하거나 시도한 경우
a. 합리적 사유 없이 상습적·악의적으로 이의를 제기하는 행위
b. 구매한 상품 또는 서비스에 특별한 하자가 없음에도, 상습적으로 사용 후 취소, 반품 등을 하는 행위
c. 그 외 회사가 정한 안전거래 이용규칙을 위반한 경우
다. 고의로 회사의 영업을 방해 하거나 판매부적합 상품을 등록 할 경우
라. 회원이 이 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우
마. 회원에게 제8조에서 정한 이용계약의 승낙거부사유가 있음이 확인된 경우
2. 회사가 해지할 때에는 회사는 회원에게 이메일(E-mail), 전화, 팩스, 기타의 방법으로 해지 사유를 밝혀 해지 의사를 통지합니다. 이용계약은 회사의 해지의사가 회원에게 도달한 시점에 종료됩니다. 단, 이메일(E-mail)을 통한 해지의사 통지의 경우 발송시점을 도달시점으로 간주합니다.
3. 본 항에 따라 회사가 이용계약을 해지하더라도, 해지 이전에 이미 체결된 매매계약의 완결에 관해서는 이 약관이 계속 적용됩니다.
4. 본 항에서 정한 바에 따라 이용계약이 종료될 때에는 회사는 별도 통지 없이 해당 회원과 관련된 광고와 거래를 취소할 수 있고, 구매자가 상품구매대금을 신용카드로 결제했을 때에는 해당 신용카드매출을 취소할 수 있으며, 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
5. 본 항에서 정한 바에 따라 이용계약이 종료되면 회사는 회원의 재이용 신청을 그 승낙하지 않을 수 있습니다.
③ 이용계약의 종료와 관련하여 발생한 손해에 대해서는 자신의 귀책사유로 이용계약이 종료된 해당 회원이 책임을 져야 하고, 회사는 귀책사유가 없는 한 책임을 지지 않습니다.

제10조 [회원등급 및 평가제도]
① 회사는 서비스를 이용하여 상품을 거래했을 때 부과되는 점수를 기준으로 하여 회원등급 및 등급에 따른 일정 혜택 등을 회원에게 부여할 수 있습니다. 회원등급 및 그에 따른 혜택 등은 서비스 이용실적, 기타 회사가 정하는 이용정책에서 정한 기준에 따라 부여되며, 자세한 내용은 해당 서비스 화면에 게재하거나 회사가 운영하는 인터넷 사이트 초기화면에 공지합니다
② 해당 회원이 자신에게 부여된 등급 구성요소에 대하여 이의를 제기하면 회사는 소명내용, 해당 회원의 신용도 등 제반 상황을 고려하여 등급 구성요소의 전부 또는 일부를 조정할 수 있습니다.
③ 회원의 등급은 상품매매 때 참고자료로 활용될 뿐이며, 회원의 신용을 보증하거나 금융상의 신용상태를 의미하는 것은 아닙니다.
④ 구매결정 때 상품의 구매자는 해당 거래에 대한 만족도를 회사가 정한 단계별로 표시할 수 있으며, 표시된 만족도는 해당 상품 판매자의 평가에 반영됩니다. 거래만족도 항목 및 단계는 해당 서비스 화면에 표시합니다.
⑤ 상품평은 상품을 구매한 구매자가 작성할 수 있으며 공개를 원칙으로 합니다. 상품평 작성자는 자신이 작성한 상품평을 삭제할 수 있습니다. 상품평과 첨부된 의견의 내용이 욕설, 비방, 명예훼손 등의 내용이거나 상품 평가와 무관한 내용을 담고 있어 공개가 부적절하다고 판단되면, 회사는 해당 상품평과 첨부된 의견을 삭제할 수 있습니다. 다만 상품에 대한 불만, 판매자에게 불리한 내용이라는 이유만으로는 삭제하지 않습니다. 상품평을 삭제할 때에는 상품평에 첨부된 의견도 함께 삭제됩니다.
⑥ 거래 상대방에 대한 거래만족도 평가와 상품평 작성은 전적으로 평가 당사자의 책임 하에 이루어지며, 회사는 내용의 사실 여부에 개입하지 않습니다.
⑦ 회원이 회원등급 및 회원평가제도의 목적과 취지에 어긋나는 행위를 하면 회사는 해당 평가결과를 삭제하고 관련 회원에 대한 서비스 이용자격을 정지하는 등 제재를 가할 수 있습니다.
⑧ 아래와 같은 내용에 해당하면 상품평을 삭제하거나 회원의 이용자격이 정지될 수 있습니다.
1. 회원등급 또는 거래만족도를 높이려는 목적으로 만족도를 평가하는 행위
2. 높은 만족도를 주고 상품평을 쓰는 대가로 금전 또는 기타의 보상을 받거나 받기로 약정하고 이루어진 평가결과 및 그 평가행위
3. 상품평을 통하여 타인을 모욕하거나 타인의 명예와 신용을 훼손하는 등의 행위 및 그 평가결과
4. 기타 회원평가제도의 목적과 취지에 반하여 평가제도를 이용하는 행위

제11조 [회원관리]
① 회사는 이 약관과 관계 법령 및 상거래의 일반원칙을 위반한 회원에게 다음과 같은 조치를 할 수 있습니다.
1. 신용점수 차감
2. 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수
3. 특정 서비스 이용제한
4. 이용계약의 해지
5. 손해배상의 청구
② 회원은 본 조에 따른 회사의 조치에 대해 항변의 사유가 있으면 항변을 할 수 있습니다.
③ 회사는 회원이 1년 이상 로그인 하지 않으면, 휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 제한할 수 있습니다. 휴면계정으로 지정된 이후 다시 서비스를 이용하려면 거래안전을 위해 회사가 정하는 본인확인 절차 등을 이행하여야 합니다.

제12조 [서비스 기간과 중단]
① 회원의 서비스 기간은 사이트에 서비스를 신청하여 이용승낙을 받은 날로부터 서비스 이용계약을 해지할 때까지 입니다.
② 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생하면 서비스를 일시적으로 중단할 수 있습니다.
③ 회사는 천재지변 또는 이와 같은 불가항력으로 서비스를 제공할 수 없으면 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.
④ 회사는 본조 2항의 사유로 발생한 회원 또는 제3자가 입은 손해에 대해서 배상하지 아니합니다. 단, 회사의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.

제13조 [서비스 이용료]
① 회사는 사이트의 일부 특정 서비스나 기능을 제공할 때 이용료를 부과할 수 있습니다
② 본 조의 이용료는 본 조 및 개별 약관 또는 별도 신청서에서 정하는 바를 따릅니다.

제14조 [마일리지]
① 마일리지는 사이트 내 모든 서비스를 현금처럼 사용할 수 있는 전자적 정보입니다.
② 마일리지는 적립성 마일리지와 현금성 마일리지로 구분됩니다.
1. 적립성 마일리지 : 회원이 서비스 이용시 회사가 정한 기준에 따라 적립되는 점수로, 사용처에 제한이 있으며 유효기간(1년)이 경과하면 자동으로 소멸됩니다.
2. 현금성 마일리지 : 회원이 무통장입금, 카드결제 등의 결제수단을 이용해 직접 충전한 점수로, 사용처에 제한이 없으며 유효기간(5년)이 경과하면 자동으로 소멸됩니다.
③ 현금성 마일리지 충전금액은 충전 후 잔여금액에 대하여 요청시 현금으로 환급가능합니다.

제15조 [저작권 정책]
① 회사가 작성한 저작물에 대한 저작권과 지적 재산권은 회사에 귀속합니다.
② 이용자는 회사를 이용함으로써 얻은 정보 기타 자료를 회사의 사전 동의 없이 복제, 송신, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 해서는 안됩니다.
③ 이용자는 회사에 게시된 정보, 문서 기타 모든 자료를 회사의 동의 없이 반복적으로 혹은 특정한 목적을 위하여 수집해서는 안됩니다.
④ 회원이 회사가 제공하는 서비스를 이용하면서 작성한 각종 게시물의 저작권은 회원 본인에게 있으며, 해당 게시물의 저작권 침해를 비롯한 민·형사상 모든 책임은 회원 본인이 집니다.
⑤ 회원은 상품, 서비스 등의 등록 및 판매, 이벤트, 기획전 등과 관련하여 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지적 재산권을 침해하지 않아야 하며, 제3자의 지적 재산권을 사용할 때에는 정당한 권리자로부터 사용 허락을 받은 후에 사용해야 합니다.
⑥ 회원은 사이트에 등록, 사용한 정보와 관련하여 제3자로부터 소송 또는 소송 이외의 방법 등으로 이의제기를 받게 되면 회사(및 사이트)를 면책시켜야 하며, 면책에 실패한 경우 그로 인해 회사(및 사이트)가 입은 모든 손해를 배상하여야 합니다.
⑦ 회원이 등록, 사용한 제반 정보에 대하여 제3자가 지적 재산권과 관련한 권리침해를 주장하면 회사는 판매자가 제3자의 권리침해가 아님을 입증(법원의 판결 또는 검찰의 불기소처분 등)할 때까지 해당 자료와 서비스에 관한 정보의 등록 및 게시를 중지할 수 있습니다.
⑧ 회사는 회사가 제공한 양식에 맞추어 회원이 등록한 체계적인 자료(상품정보 등)에 대해 독점적 이용 및 배포 권한을 갖습니다. 회사는 회원이 등록한 게시물을 검색사이트나 다른 사이트에 노출할 수 있으며, 판촉, 홍보 및 기타의 자료로 사용할 수 있습니다. 또한, 회사가 제공하는 서비스 내에서 회원 게시물을 복제, 전시, 전송, 배포할 수 있으며 2차적 저작물과 편집저작물로 작성 할 수 있습니다. 다만, 해당 게시물을 등록한 회원이 게시물의 삭제 또는 사용중지를 요청하면 회사는 관련 법률에 따라 보존해야 하는 사항을 제외하고 즉시 삭제 또는 사용을 중지합니다.
⑨ 회원은 회사에 등록한 체계적인 자료 및 회사를 이용함으로써 얻은 정보 기타 자료를 회사의 사전동의 없이 복제, 송신, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에가 영리목적으로 복제하게끔 권한을 위임하거나 양도할 수 없습니다.
⑩ 회원은 사이트 내에서 자신의 저작권이 침해되면 회사가 운영하는 신고센터 제도를 이용하여 자신의 정당한 권리를 보호받을 수 있습니다.
⑪ 게시물의 내용이 다음 각 호의 내용을 담고 있을 때회사는 게시물을 삭제하거나 게시자에게 특정 서비스의 이용제한 또는 정지, 이용계약의 해지 등의 조치를 할 수 있습니다. 이때 게시물이 삭제되면 해당 게시물과 관련된 게시물(답변글, 댓글 등)도 모두 삭제됩니다.
1. 대한민국의 법령을 위반하는 내용을 포함하는 경우
2. 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우
3. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우
4. 사회 공공질서나 미풍양속에 위배되는 경우
5. 기타 제1호 내지 제4호에 준하는 중대한 사유로 말미암아 회사가 제공하는 서비스의 원활한 진행을 방해하는 것으로 판단되는 경우
⑫ 회사가 제8항에 따라 게시물을 삭제했을 때, 해당 게시자는 회사에 이의를 제기하여 구제받을 수 있습니다.



제3장 중개서비스 판매 이용규칙

제17조 [안전거래수수료]
① 안전거래수수료(이하 “수수료”)는 회사가 제공하는 서비스를 이용하는 데 따른 대가로 판매자가 회사에 지급해야 하는 금액을 의미하며, 회사는 판매대금 또는 구매자로부터 예치받은 금액에서 수수료를 공제하는 등으로 판매자에게 정산합니다.
② 수수료율은 판매가(즉시구매가)에 회사가 정한 비율(이하 “수수료율”이라 합니다)을 곱한 금액입니다.
③ 수수료율에 대한 상세 규칙은 사이트에 공지하며, 회사와 판매자 간의 협의 또는 회사의 내부 사정에 따라 변경될 수 있습니다.
④ 사업자 또는 법인 회원에게 공제한 수수료 금액과 제휴사에 등록되어 인상된 금액(단 쿠폰, 기타할인 등 제외)을 수수료 세금계산서 발행을 합니다.

제18조 [판매자의 관리의무]
① 사이트에서 상품과 용역의 판매는 인증회원이 되는 동시에 가능하며, 이를 위해서 판매자는 상품과 용역에 관한 정보를 직접 등록, 관리해야 합니다. 이때 상품과 용역의 판매가격은 표준마진율, 배송비, 서비스이용료 등을 고려하여 판매자 스스로 결정합니다.
② 판매자는 재고 수량 등 수시로 바뀌는 사항에 대한 데이터를 적절히 관리해야 하며, 판매자는 데이터를 허위로 기재할 수 없습니다. 회사가 정한 양식에 어긋난 등록이나 허위등록으로 인한 손해 또는 손실에 대한 책임은 판매자가 부담합니다.
③ 판매자는 전자상거래 등에서의 소비자보호에 관한 법률, 전자금융거래법, 여신전문금융업법, 전기통신사업법, 부가가치세법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 상표법, 저작권법 등 사이트에서 상품과 용역의 판매와 관련하여 법령이 요구하는 사항을 지켜야 합니다.
④ 판매자는 회사가 서면으로 미리 승인하지 않으면 사이트의 상호나 로고 등을 사용할 수 없습니다.
⑤ 판매자는 사이트를 통하지 않고 구매자에게 직접 상품을 판매하거나 이를 유도해서는 안 되며, 이를 위반하면 회사는 해당 판매자의 서비스 이용을 정지하거나 서비스 이용계약을 해지할 수 있습니다.
⑥ 판매자는 판매된 상품 및 용역에 대한 보증 서비스를 자신의 책임과 비용으로 시행하여야 합니다.
⑦ 판매자는 구매회원의 문의에 성실하고 정확하게 대답해야 합니다. 판매자의 불성실, 부정확한 답변으로 구매회원에게 손해가 발생하면 판매자가 이에 대해 책임을 부담합니다.
⑧ 판매자는 상품의 판매와 관련하여 특정한 인허가 자격이 요구되면 이에 대한 요건을 만족한 후 판매상품을 광고 또는 등록해야 합니다. 인허가 자격을 갖추지 않은 상태에서 관련 상품을 판매하여 발생한 모든 민형사상의 책임은 판매자 본인에게 있습니다.
⑨ 판매자는 상품 등록 후 장기간 판매가 없는 상품 등록을 유지할 수 없으며, 이를 위반하면 회사는 별도 통지 없이 상품 검색의 효율성 제고와 구매자 편의 개선을 위해 해당 상품을 삭제하거나 판매 중단 처리할 수 있습니다.

제19조 [제휴연동서비스]
① “제휴연동서비스“란 판매자가 프라이스골프에 등록한 상품정보를 회사와 제휴관계에 있는 타 회사로 공급하여 보다 많은 판매경로를 확보하고, 이 중 어느 경로에서 주문이 발생하더라도 프라이스골프에서 주문을 관리할 수 있는 서비스를 말합니다.
② 제휴연동서비스는 기본적으로 모든 판매자의 모든 판매상품에 대해 적용됩니다. 단, 판매자가 제휴연동서비스 이용을 거부하거나, 회사가 판단하기에 제휴연동서비스를 제공할 수 없는 사유가 있는 경우, 회사는 특정 상품 또는 특정 계정에 대한 제휴연동서비스 적용 범위를 조정할 수 있습니다.
③ 제휴연동서비스로부터 발생한 주문은 구매가 이루어진 사이트의 약관과 프라이스골프의 약관이 모두 적용되며, 두 약관의 내용이 상충되거나 명시되지 않음으로써 발생한 모든 문제는 전자상거래법 등 관련 법령을 따릅니다.
④ 회사는 판매자가 제휴연동서비스를 이용하면서 제휴사의 약관을 따르지 않아 발생하는 손해에 대해 책임지지 않습니다. 또한, 판매자가 제휴연동서비스를 이용하는 과정에서 약관 및 관련 법령을 준수하지 않아 회사, 제휴사 및 제휴관계에 문제를 야기할 경우 회사는 판매자에게 손해배상을 청구할 수 있습니다
⑤ 회사는 판매자가 등록한 상품 판매가를 제휴연동서비스시 각 제휴처와의 약정 사항에 따라 판매가를 동일하거나 높게 연동되어 판매, 노출될 수 있습니다. (단, 제휴처에 마케팅 프로모션 차원에 할인, 쿠폰 정책으로 인해 판매가가 할인되어 판매, 노출될 수 있습니다.)

제20조 [상품의 배송]
① 구매자의 주문에 따른 결제가 완료된 순간 회사는 판매자에게 주문 정보를 전달하고, 판매자는 해당 주문 정보에 따라 배송을 하여야 합니다.
② 판매자는 주문내역을 확인하고 배송 중 상품이 파손되지 않도록 적절한 포장을 한 후 배송을 증명하거나 추적할 수 있는 물류대행(택배)업체에 배송을 위탁하여야 합니다.
③ 전상법 제15조 1항에 따라 판매자는 구매자의 결제일로부터 3영업일 이내에 상품 등의 공급을 위하여 필요한 조치를 하여야 하고(단, 구매자와 판매자 간에 상품등의 공급시기에 관하여 따로 약정한 경우는 제외), 판매자 페이지에서 송장번호 등의 발송 관련 데이터를 입력하여 발송이 완료되었음을 증명하여야 합니다.
④ 판매자가 전항의 기한 내에 발송하지 않거나, 배송지 오류 등으로 구매자가 상품을 정상적으로 받지 못하면 판매자는 그에 관한 모든 책임을 부담하여야 합니다.
⑤ 회사가 구매자의 구매대금 결제 확인을 통지한 후 상당 기간 배송 등 후속 절차를 이행하지 않으면 회사는 별도의 개별 정책으로 구매자에게 자동으로 환불 처리하는 등의 조치를 할 수 있습니다. 다만, 구매자가 희망하지 않는 경우는 제외하며 개별 정책은 미리 공지합니다.
⑥ 만약 판매자의 책임있는 사유로 상품을 배송할 수 없을 경우 구매자에게 사실을 알리고 판매를 취소할 수 있습니다. 단, 판매취소가 일정 수준을 초과하면 회사는 판매자에게 판매신용도 하향조정, 안전거래수수료율 인상, 일정 기간 판매 제한, 영구 판매 제한 등의 패널티를 부여할 수 있습니다. 이는 구매자가 취소요청을 한 경우에도 그 사유가 판매자로부터 기인했을 경우 동일하게 적용됩니다.

제21조 [교환, 환불]
① 구매자가 상품 수령 후 교환이나 환불을 요청하면 판매자는 전상법 등 관련 법률에 따라 반품을 받은 후 교환이나 환불을 해주어야 하며, 추가로 발생하는 비용은 교환이나 환불의 책임이 있는 측에서 부담합니다.
② 판매자는 상품의 하자 또는 사용상의 안전성에 결함이 있으면 전량 리콜(수리, 교환, 환불)하여야 하며, 리콜에 따른 모든 비용을 부담하여야 합니다.
③ 판매자는 전상법 제17조 제2항에 따라 청약철회가 제한되는 사유에 해당되는 경우 청약철회가 불가능하다는 사실을 전상법 제17조 제6항에 따라 상품의 포장 기타 소비자가 쉽게 알 수 있는 곳에 명기하여야 합니다.
④ 상품을 반품받은 판매자는 배송비 정산, 사은품의 반환 확인 등이 필요하면 반품을 보류할 수 있고, 제3항에 따른 청약철회 제한 사유에 해당하는 등 정당한 사유가 있으면 반품을 거부할 수 있습니다.
⑤ 판매자는 구매자가 반품한 상품을 수령해야 하며, 판매자의 연락 두절 등의 사유로 반품배송을 할 수 없어 구매자가 직접 회사로 상품을 보낼 때에는 회사는 상당 기간을 정하여 해당 상품을 수령할 것을 판매자에게 통지하고, 판매자가 해당 기간 내에 특별한 의사를 표시하지 않으면 회사는 해당 상품을 폐기할 수 있습니다.
⑥ 전자상거래법 등에서의 소비자보호에 관한 법률(약칭:전자상거래법)에 따르면 온라인의 경우 원칙적으로 단순 변심이더라도 일정 기간 내(7일)에 소비자가 교환 및 반품을 요청했다면 판매자는 교환/환불을 보장할 의무가 있습니다.
⑦ 회사는 판매자의 사유로 배송 완료까지 상당 시간이 흐르거나 취소율이 일정 수준을 초과하면 경고, 신용점수 차감, 아이디(ID) 정지 등 서비스 품질향상을 위하여 해당 판매자에게 필요한 조치를 할 수 있습니다.

제22조 [판매대금의 정산]
① 판매자가 발송데이터를 입력한 날로부터 4일 이상 경과하고 구매자가 서비스 화면에서 구매결정을 클릭하거나 자동구매결정이 되면 회사는 그로부터 2일(공휴일, 토요일, 일요일 제외) 이내에 판매대금에서 서비스이용료, 회사에 대한 미납금 및 기타 채무금을 공제한 나머지 금액을 판매자에게 송금합니다. 다만, 구매자가 신용카드로 결제했을 때에는 회사는 허위등록 또는 판매위장등록, 신용카드 결제시스템만을 이용하기 위한 허위거래가 의심되는 거래의 해당 내역을 확인하기 위하여 최고 60일까지 송금을 보류할 수 있습니다. 이 경우 판매자가 물품판매에 관한 거래사실 증빙서류를 회사에 제공한다면 회사는 이를 확인한 후 현행법상 문제없을 시 송금 처리할 수 있습니다.
② 제휴처(오픈마켓, 소셜커머스, 홈쇼핑사등)에 따라 배송완료 된 날로부터 최대 40일이 경과되어 정산금액이 지급될 수 있습니다.
③ 구매결정 기간 내에 구매자가 구매결정, 교환 또는 반품에 대한 의사표시를 하지 않아 구매결정의 의사가 있는 것으로 간주되면 회사는 자동구매결정으로 처리할 수 있습니다.
④ 회사는 사이트를 통하여 판매금액, 공제금액, 정산금액을 판매자에게 통보합니다.
⑤ 회사는 판매자가 회원정보상에 기재한 정산계좌로 판매대금을 송금합니다. 단, 정산계좌의 예금주는 판매자와 동일인임을 원칙으로 합니다.
⑥ 사업자 또는 법인일 경우 정산계좌의 예금주는 사업자, 법인 명의 일치하는 계좌를 등록하여야 합니다.

제23조 [정산의 보류]
① 회사는 판매자의 귀책사유로 발생한 비용을 판매대금 정산 때 공제할 수 있으며, 판매자와의 이용계약 종료 후에는 해당 판매자의 판매대금의 일정 비율에 해당하는 금액을 이용계약 종료일로부터 일정 기간 예치하여 이 기간에 구매자로부터 환불, 교환 등 이의제기가 있을 때 관련 비용의 지급에 사용할 수 있습니다.
② 회사는 장기간 배송지연 건을 배송완료 건으로 간주하여 주문 절차를 종결할 수 있되, 판매대금의 정산은 향후 구매자의 환불 요청에 대비하여 일정 기간 유보할 수 있습니다.
③ 판매자의 채권자가 신청한 사항에 대해 법원이 판매대금의 가압류, 압류 및 추심명령 등을 결정하면, 회사는 판매자와 채권자 간의 합의 또는 채무액의 변제 등으로 이 결정이 해제될 때까지 판매대금의 정산을 중지할 수 있습니다.
④ 본 조에 정한 것 외에도 법률의 규정이나 합리적인 사유(구매자 클레임 다수 발생시 환불요청 대비 등 구매자 보호를 위한 경우, 부정거래 발생, 법원/금융기관/수사기관 등 권한 있는 기관의 요청이 있는 경우)가 있으면 회사는 해당 사항을 판매자에게 통지하고 판매대금의 전부 또는 일부의 정산을 일정 기간 유보하거나 상계할 수 있습니다.

제24조 [개인정보의 보호]
① 판매자는 판매서비스를 이용하면서 얻은 타인의 개인정보를 이 약관에서 정한 목적 이외의 용도로 사용할 수 없으며, 이를 위반하면 관련 법령에 따라 모든 민/형사상의 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야만 하며, 회사는 해당 판매자를 탈퇴시킬 수 있습니다.
② 회사는 개인정보 보호를 위하여 배송 등의 목적으로 판매자에게 공개된 구매회원의 개인정보를 상당 기간이 지난 후 비공개 조치할 수 있습니다.
③ 회사가 개인정보를 보호하는 데 상당한 주의를 기울였음에도 특정 판매자가 제1항을 위반하여 타인의 개인정보를 유출하거나 유용했을 때 회사는 그에 대하여 아무런 책임을 지지 않습니다.
④ 전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사관서 등이 판매자에 관한 정보의 제공을 회사에 요청했을 때 회사는 해당 자료를 제출할 수 있습니다.
⑤ 판매자가 불법행위를 하였다면 회사는 이에 대한 수사 등을 의뢰하기 위하여 관련 자료를 수사관서 등에 제출할 수 있고, 판매자는 이에 동의합니다.
⑥ 판매자는 회사로부터 제공받은 개인정보를 제공받은 목적의 용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 그 기간이 종료될 경우 즉시 파기해야 합니다. 또한 제공받은 개인정보의 주체로부터 직접 파기 요청을 받은 경우 이에 응해야 합니다.
⑦ 판매자는 관련 법령 및 방송통신위원회 고시에 따라 회사로부터 제공받은 개인정보를 보호하기 위하여 기술적, 관리적 보호조치를 취해야 합니다.

제25조 [판매제한]
① 회사는 판매자에게 다음 각 호의 사유가 발생했을 판매자의 판매활동을 제한할 수 있습니다.
1. 이용계약(프라이스골프 이용약관, 전자금융거래 이용약관, 개인정보처리방침 등을 포함)의 의무를 위반하여 상대방으로부터 그 시정을 요구받은 후 7일 이내에 이를 바로잡지 않은 경우
2. 부도 등 금융기관의 거래정지, 회생 및 파산절차의 개시, 영업정지 및 취소 등의 행정처분, 주요 자산에 대한 보전처분, 영업양도 및 합병 등으로 이용계약을 더는 이행할 수 없는 경우
3. 관련 법령을 위반하거나 판매자의 책임 있는 사유로 회사가 명예 실추 등 유/무형적 손해를 입은 경우
4. 판매자의 책임 있는 사유로 다수의 구매자로부터 이의가 제기된 경우
5. 판매 상품에 대한 중요한 정보를 잘못 기재하거나 기재하지 않아서 소비자에게 혼란과 불만을 유발하는 경우
6. 그 밖에 회사의 안전거래 이용규칙 위반 사항에 해당하는 행위를 한 경우
③ 판매자는 언제든지 회사에 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다.
④ 이용계약이 해지될 때까지 판매자는 완결되지 않은 주문 건의 배송, 교환, 환불에 필요한 조치를 해야 하며, 해지 이전에 이미 판매한 상품에 대한 판매자의 책임과 관련 조항은 그 효력을 유지합니다.

제26조 [손해배상]
① 당사자 일방 또는 당사자 일방의 피고용인, 대리인, 기타 도급 및 위임 등으로 당사자 일방을 대신하여 이용계약을 이행하는 자의 책임 있는 사유로 말미암아 이 계약의 이행과 관련하여 상대방에게 손해가 발생하면, 그 당사자 일방은 상대방에게 발생한 손해를 배상할 책임이 있습니다.
② 판매자가 이용계약을 위반하여 회사 또는 사이트의 대외 이미지 실추 등 회사에 유, 무형적 손해가 발생하면, 판매자는 회사의 손해를 배상해야 합니다.

제27조 [회사의 면책]
① 회사는 사이트를 기반으로 한 거래시스템만을 제공할 뿐, 판매회원이 등록한 상품과 용역, 그에 관한 정보 등에 대한 책임은 판매자에게 있습니다. 또한 판매자와 구매자와의 거래에서 분쟁이 발생했을 때 회사는 그 분쟁에 개입하지 않으며 분쟁의 결과에 대한 모든 책임은 판매자가 부담합니다. 이와 관련하여 회사가 제3자에게 손해를 배상하거나 기타 비용을 지출하게 된다면 회사는 판매자에게 구상권을 행사할 수 있습니다. 단, 회사는 분쟁의 합리적이고 원활한 조정을 위하여 회사가 설치/운영하는 분쟁조정센터(고객센터 포함)를 통하여 예외적으로 해당 분쟁에 개입할 수 있으며, 판매자는 분쟁조정센터의 결정을 신의성실의 원칙에 따라 최대한 존중해야 합니다.
② 회사는 권리자의 적법한 요구가 있으면 해당 상품과 용역 등에 관한 정보를 삭제하거나 수정할 수 있으며, 판매자는 이에 따른 손해배상을 회사에 청구할 수 없습니다.
③ 회사는 전상법 제20조 제2항에 따라 판매자의 정보를 열람하는 방법을 구매자에게 제공할 수 있으며, 판매자는 해당 정보를 기재하지 않거나 허위로 기재하여 발생하는 모든 책임을 져야 합니다.
④ 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신 두절 등의 사유가 발생하면 판매서비스의 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.

제28조 [매매부적합상품]
① 다음 각 호의 매매부적합상품은 판매를 금지하며, 매매부적합상품을 판매했을 때의 모든 책임은 해당 매매부적합상품을 등록한 판매자가 부담합니다.
1. 허위 또는 과장 광고한 상품
2. 지적 재산권, 상표권 등 타인의 권리를 침해하는 상품
3. 장물이나 습득한 유실물
4. 기타 관계 법령에 저촉되거나 회사가 합리적인 사유로 판매를 금지하는 상품
② 회사는 매매부적합상품이 발견되면 해당 상품의 광고를 삭제하거나 그 판매를 중지시킬 수 있으며, 해당 상품이 이미 판매되었다면 그 거래를 취소할 수 있습니다. 이때 판매자가 취소된 거래와 관련하여 지급한 서비스 이용료는 환불되지 않습니다.
③ 회사는 매매부적합상품을 등록한 판매자의 회원 자격을 정지시키거나 탈퇴시킬 수 있으며, 매매부적합상품으로 입은 손해를 해당 판매자에게 청구할 수 있습니다.
④ 판매자가 제1항의 매매부적합상품 중 위조품을 판매하여 구매자에게 손해가 발생했을 때 회사는 손해에 상당하는 금액(구매대금 및 구매자의 정신적 피해에 대한 보상)을 구매자에게 직접 지급할 수 있습니다. 이때 회사는 구매자에게 지급한 구매대금, 정신적 피해에 대한 보상금(금전적 가치로 환산할 수 있는 수단을 포함함) 및 위 절차와 관련하여 회사가 지출한 제반 경비(상품 운송비 등)를 해당 위조품을 판매한 판매자에게 청구할 수 있습니다.
⑤ 제4항은 제15조의 회사의 면책을 부인하는 것으로 해석될 수 없습니다.

제29조 [금지행위]
① 판매자가 물품등록 시 직거래를 유도하는 항목을 삽입했다고 회사가 판단하는 경우, 회사는 해당 중개물품을 임의로 삭제할 수 있습니다.
② 판매자는 회사가 승인하지 않은 제 3자 외부 프로그램 등을 이용해 상품 판매 활동을 해서는 안됩니다.
③ 판매자는 회사 또는 사이트의 경영 또는 영업 활동을 방해하는 불공정행위를 직,간접적으로 하거나 제3자로 하여금 하게 해서는 안 됩니다. 특히 판매자는 직접 또는 회사(또는 사이트)와 경쟁관계에 있는 제3자의 회사(이하 "경쟁회사") 또는 해당 사이트와 연계,공조하여 ‘독점규제 및 공정거래에 관한 법률’상 금지되는 불공정행위(예컨대, 사업활동방해 등)등을 하여 회사나 사이트의 영업에 피해를 주는 행위를 해서는 안 됩니다.
④ 회사는 회사와 다른 판매자의 정상적인 영업활동을 방해하는 판매방식 행위를 금지하고 있으며 판매자에게 해당 상품의 수정을 요구할 수 있고, 적발횟수에 따라 해당 상품을 판매금지 또는 해당 판매자에게 사이트 이용을 제한할 수 있습니다.
1. 중복등록이란 같은 카테고리와 인접 카테고리에 같은 상품(같은 상품으로 간주되는 경우를 포함)을 같거나 유사한 판매조건으로 복수 등록하는 것을 말합니다.
2. 스팸성 키워드란 검색노출을 확장하려고 판매하는 상품과 관련 없는 브랜드명, 물품명, 인기 키워드를 상품등록 내용에 기재하는 것을 말합니다.
3. 회사는 기타 비정상적인 방법으로 상품을 노출하는 모든 행위를 금지합니다.
⑤ 회사는 판매자의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 모니터링을 실시하고 있으며, 판매자는 회사의 모니터링 업무에 대하여 협조하여야 하며 회사가 요청하는 필요 최소한의 증빙자료를 제출할 의무가 있습니다.
⑥ 판매자가 제1항, 제2항, 제3항, 제4항, 제5항 및 이 약관에서 금지하는 행위를 했을 때 회사는 판매자의 의견 청취 절차를 거쳐 그 소명이 타당하지 않다고 판단되면 판매자의 서비스 이용정지, 계약해지 등 회사의 손해를 최소화할 수 있는 조치를 할 수 있습니다. 단, 판매자가 회사의 연락을 회피하거나 해외에 거주하는 등으로 의견 청취가 곤란한 경우, 위조품 판매, 법령 위반의 부정거래 등 구매자의 이익 보호를 위하여 신속한 조치가 필요한 경우에는 회사는 선 조치를 진행할 수 있습니다.

제30조 [비밀유지]
① 각 당사자는 법령상 요구되는 경우를 제외하고는 상대방으로부터 취득한 구매자정보, 기술정보, 생산 및 판매계획, 노하우 등 비밀로 관리되는 정보를 제3자에게 누설해서는 안 되며, 그 정보를 이용계약의 이행 이외의 목적으로 사용해서는 안 됩니다.
② 제1항의 의무는 이용계약의 종료 후에도 3년간 존속합니다.

제31조 [보칙]
② 각 당사자는 상대방의 서면 동의 없이 이용계약상의 권리와 의무를 제3자에게 양도하거나 처분할 수 없습니다.
③ 이 약관과 관련하여 당사자가 서로 합의하여 추가로 작성한 계약서, 협정서, 약정서 등과 회사의 정책변경, 법령의 제·개정 또는 공공기관의 고시·지침 등에 따라 회사가 사이트 또는 판매자툴을 통해 판매자에게 공지하는 내용도 이용계약의 일부를 구성합니다.
④ 판매 이용규칙 및 개별 약관에서 정하지 않은 사항은 중개서비스 구매 이용규칙을 따르며, 구매 이용규칙에서 정하지 않은 사항은 관련 법령과 상관례를 따릅니다.



제4장 중개서비스 구매 이용규칙

제32조 [대리행위 및 보증인의 부인]
① 회사는 구매자와 판매자 간의 자유로운 상품의 거래를 위한 시스템을 운영 및 관리를 제공할 뿐이므로 구매자 또는 판매자를 대리하지 않으며, 이용자 사이에 성립된 거래와 관련된 책임과 이용자가 제공한 정보에 대한 책임은 해당 이용자가 직접 부담하여야 합니다.
② 회사는 구매자와 판매자 간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체를 보증하지 않습니다. 다만, 등록된 상품 관련 정보가 법규 위반이나 저작권 침해가 명백한 경우, 또는 저작권 등 권리를 침해받았다고 주장하는 자가 그 침해의 정지ㆍ예방을 요청하는 경우 회사는 당사자간의 분쟁이 해결되어 권리관계가 명확해질 때까지 해당 상품에 대한 삭제, 수정 또는 미노출 처리할 수 있습니다.
③ 회사는 구매자와 판매자간의 거래의 안전성과 신뢰성을 높이는 도구만을 개발하여 제공합니다. 단, 회사가 판매하는 상품의 경우 구매자에 대하여 회사는 판매자의 지위를 갖게 됩니다.

제33조 [구매자의 서비스 이용]
① 구매자는 구매의사 없이 판매자의 상품에 입찰하거나 구매표시를 해서는 안 되며, 구매의사 없이 입찰행위를 통하여 상품 가격을 올리거나 구매의사를 표시하여 판매자의 상품판매 기회를 방해하거나 다른 구매자의 구매기회를 방해한 것이 적발되면 회사는 해당 아이디(ID)[해당 아이디(ID)와 동일인 소유로 확인되는 아이디(ID) 포함]에 대하여 회원자격을 정지하는 등의 조치를 할 수 있습니다.
② 구매자는 상품을 구매하기 전에 반드시 판매자가 사이트 내에 작성한 상품의 상세 내용과 거래의 조건을 정확하게 확인해야 합니다. 구매하려는 상품의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손실과 손해는 구매자 본인에게 있습니다.
③ 회원 구매자은 상품의 상세 내용과 거래의 조건 등에 관한 내용을 판매자에게 확인하기 위하여 회사가 제공하는 쪽지, 상품Q&A 서비스를 이용할 수 있습니다.
④ 구매자는 이 약관과 회사가 서비스 화면에서 알리는 내용을 지켜야 하며, 약관과 알리는 내용을 위반하거나 이행하지 않아서 발생하는 모든 손실과 손해에 대해 책임을 집니다.
⑤ 회사는 판매자가 등록한 상품의 내용과 거래조건에 대해서 어떠한 보증이나 대리를 하지 않습니다. 따라서 구매자는 스스로 책임지고 상품을 구매해야 합니다.
⑥ 구매자는 구매한 상품에 청약철회의 원인이 발생하면 수령한 상품을 임의로 사용하거나 훼손되도록 방치해서는 안 됩니다. 청약철회 상품의 임의사용이나 상품보관의 미흡으로 상품이 훼손되었을 때에는 그에 합당한 비용을 부담하여야 합니다.
⑦ 구매자는 판매자와 상품매매 절차에서 분쟁이 발생하면 분쟁의 해결을 위하여 성실히 임해야 하며, 분쟁해결 과정에서의 불성실 등 구매자의 귀책사유로 판매자와 회사에 손실과 손해가 발생하면 그에 대한 모든 책임을 져야 합니다.
⑧ 구매자가 상품을 구매할 때는 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을 임의로 사용 해서는 안 됩니다. 타인의 결제수단을 임의로 사용하여 발생하는 회사, 결제수단 소유자, 판매자의 손실과 손해에 대한 모든 책임은 구매자에게 있습니다.
⑨ 매매대금의 결제와 관련하여 구매자가 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 구매자가 감당해야 합니다.
⑩ 회사는 구매자가 매매대금 결제 때 사용한 결제수단에 대해 정당한 사용권한이 있는지 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래진행을 중지하거나 해당 거래를 취소할 수 있습니다.
⑪ 회사는 구매자에게 서비스가 안전하게 제공 될 수 있도록 각종 설비와 자료를 관리해야 하며, 서비스가 제공 목적에 맞게 이용되고 있는지 확인하고 구매자에게 이용 목적에 위반되는 부분이 있는 것으로 확인되면 사유의 소명을 요청할 수 있습니다.
⑫ 회사가 구매자의 서비스 이용 편의를 높이기 위해 제휴업체로부터 정보를 제공받아 게재하거나 제3자가 제공하는 방식으로 사이트 내 또는 링크 방식으로 참조용 정보나 콘텐츠를 제공할 수 있으며, 구매자는 상품 구매 때 자신의 판단과 책임으로 결정하여 구매하여야 합니다.
⑬ 미성년자가 구매할 때 법정대리인이 해당 계약에 대하여 동의하지 않으면, 미성년자 본인 또는 법정대리인은 그 계약을 취소할 수 있습니다. 미성년자의 거래에 관한 상세한 사항은 관련 법령이 정하는 바를 따릅니다.

제34조 [할인쿠폰의 사용]
① 회사는 구매서비스를 이용하는 회원에게 상품 구매 시 일정금액 또는 일정비율을 할인 받을 수 있는 할인 쿠폰을 발급할 수 있습니다.
② 할인 쿠폰은 회원 본인의 구매에만 사용할 수 있으며, 어떠한 경우에도 타인에게 매매하거나 양도할 수 없습니다.
③ 할인 쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며 유효기간이 지난 후에는 사용할 수 없고, 상품구매 후 취소나 반품으로 환불이 이루어졌을 때에는 재사용을 할 수 없습니다.

제35조 [결제대금 보호서비스의 이용]
① 회사는 e커머스 서비스의 신뢰성을 높이고 회원 간에 안전한 거래가 이루어질 수 있도록 결제대금 보호 서비스를 제공합니다. 결제대금 보호 서비스란, 매매계약이 체결되고 구매자가 대금을 결제 하였을 경우, 구매자가 판매자로부터 해당 상품을 받았다면 회사는 미리 정한 서비스 이용료를 공제한 후 해당 판매자에게 대금을 정산해 주고, 구매자가 판매자로부터 상품을 받지 못했거나 받았더라도 판매자에게 반품한 후 환불을 요청했을 때에는 구매자가 결제한 금액을 회사가 구매자에게 환불해주는 서비스를 말합니다.
② 결제대금 보호 서비스는 사이트에서 구매하는 모든 상품에 적용 됩니다.
③ 결제대금 보호 서비스는 회원 간에 이루어지는 상품매매의 안전성과 신뢰성을 높이고, 상품을 인수하기 전에 대금을 결제해야 하는 구매자를 보호하기 위해 제공합니다.
④ 회사에서 제공하는 결제대금 보호 서비스는 판매자나 구매자를 대리하는 것이 아니며, 상품의 매매와 관련하여 판매자나 구매자의 의무를 대행하는 것도 아닙니다.
⑤ 회사의 결제대행 서비스를 통하여 상품구매대금을 결제하는 과정에서 발생하는 송금수수료 등은 대금을 송금하는 구매자가 부담해야 합니다.
⑥ 결제대금 보호 서비스를 이용하는 구매자는 구매결정기간내에 회사에 구매결정, 교환 또는 반품의 의사표시를 해야 합니다.
⑦ 구매자에게 구매결정의 의사가 있거나 구매결정의 의사가 있는 것으로 간주하는 상황이 발생하면 결제대금 보호 서비스는 종료됩니다.
⑧ 구매결정기간 내에 구매자가 교환 또는 반품의 의사표시가 없으면 회사는 구매자의 구매결정의 의사가 있다고 간주합니다.
⑨ 회사는 구매자에게 구매결정의 의사가 있거나 구매결정의 의사가 있다고 간주하면 ’전자상거래 등에서의 소비자보호에 관한 법률’의 관련 규정에 따라 관련 대금을 상품의 판매자에게 송금할 수 있습니다.
⑩ 결제대금 보호 서비스가 종료된 이후에 청약철회, 취소, 해제, 무효 등의 사유가 발생한다면 구매자는 판매자와 직접 청약철회, 취소, 해제 및 결제대금 환불 등에 관한 절차를 진행해야 합니다.

제36조 [배송 및 거래완료]
① 배송 소요기간은 입금이 확인된 날의 다음 날을 기산일로 하여 배송이 완료되기까지의 기간을 말합니다. 공휴일과 기타 휴무일 또는 천재지변 등 불가항력의 사유가 발생하면 그 해당 기간은 배송 소요 기간에서 제외됩니다.
② 배송과 관련하여 판매자와 구매자, 배송업체, 금융기관 등의 사이에 분쟁 등이 발생하면 관련 당사자가 해결해야 합니다. 회사는 이에 관여하지 않으며 어떠한 책임도 부담하지 않습니다.
③ 구매결정이 이루어진 이후에 상품의 하자 등 결제대금의 환불사유가 발생하면 회사는 이에 개입하지 않습니다. 구매결정 이후에 발생하는 판매자와 구매자 간 모든 분쟁은 거래 당사자인 판매자와 구매자가 해결해야 합니다.
④ 구매결정 기간 내에 구매자에게서 구매결정, 교환 또는 반품에 대한 의사표시가 없으면 회사는 해당 거래에 대하여 구매결정의 의사가 있는 것으로 간주하여 자동구매결정으로 처리(이하 “자동구매결정”)할 수 있습니다. 다만, 구매결정 기간 내에 구매자가 실제 상품을 수령하지 못했다면 구매자는 상품 미도착 신고를 할 수 있습니다.
⑤ 자동구매결정 처리가 완료되어 상품판매 정산금이 제휴사로부터 입금되는 시점으로 +1~2일 후 회사는 보관 중인 결제대금을 판매자가 지정한 정산계좌로 지급할 수 있습니다.
⑥ 자동구매결정이 이루어졌다면 구매자는 상품 미수령, 반품 등의 사유로 회사에 이의를 제기할 수 없으며, 상품구매대금의 환불, 상품의 교환 등 모든 문제는 판매자와 직접 해결해야 합니다.
⑦ 구매자는 거래 완료 후 중개상품 및 서비스와 관련하여 해당 판매자에 대한 평가를 내릴 수 있습니다. 판매자에 대한 평가방법은 회사가 별도로 정하는 방식에 의합니다.

제37조 [반품/교환/환불/취소]
① ’전자상거래 등에서의 소비자보호에 관한 법률’ 제17조에 따라 구매자는 상품을 배송받은 날로부터 7일 이내에 반품 또는 교환을 요청할 수 있으며, 반품에 관한 일반적인 사항은 ’전자상거래 등에서의 소비자보호에 관한 법률’ 등 관련 법령이 판매자가 제시한 조건보다 우선합니다. 단, 다음 각 호의 경우에는 구매자가 반품이나 교환을 요청할 수 없습니다.
1. 구매자의 귀책사유로 말미암아 상품 또는 포장이 훼손된 경우
2. 구매자의 사용 또는 일부 소비로 말미암아 상품의 가치가 현저히 감소한 경우
3. 시간이 지나 재판매가 어려울 정도로 상품의 가치가 현저히 감소한 경우
4. 기타 구매자가 환불이나 교환을 요청할 수 없는 합리적인 사유가 있는 경우
② 회사는 구매자로부터 교환 또는 반품의 의사표시를 접수하면, 즉시 그러한 사실을 판매자에게 통보합니다.
③ 반품이나 교환에 필요한 왕복 배송비와 기타 필요한 비용은 귀책사유가 있는 쪽에서 부담합니다.
④ 교환신청을 하더라도 판매자에게 교환할 물품의 재고가 없으면 교환할 수 없으며, 이 때는 반품으로 처리됩니다.
⑤ 교환에 드는 비용은 물품하자의 경우에는 판매자가 왕복배송비를 부담하나 구매자의 변심에 의한 경우에는 구매자가 부담합니다.
⑥ 배송상의 문제로 구매자가 손해를 보았을 때 그에 대한 책임은 해당 배송업체를 지정한 판매자에게 있습니다.
⑦ 회사가 구매자의 구매대금 결제 확인에 대해 통지한 후 판매회원이 상당 기간 구매자의 주문 정보를 확인하지 않거나 배송 절차를 이행하지 않아 구매자가 거래 취소를 요구하면 회사는 해당 거래를 취소할 수 있으며, 거래가 취소되면 보관 중인 상품 구매대금은 구매자에게 환불됩니다. 아울러, 회사는 개별적인 정책으로 구매자의 요구 없이도 자동 환불처리 등의 조치를 할 수 있으며 그럴 경우 미리 해당 내용을 구매자에게 공지합니다.
⑧ 회사는 판매자가 구매자의 교환, 반품에 대한 사실확인 진행절차에 적극적으로 임하지 않거나 지연하면 구매자에게서 교환이나 반품의 원인을 파악한 후 해당 거래를 취소하여 구매자에게 보관 중인 결제대금을 환불할 수 있습니다.
⑨ 회사는 구매자의 교환 또는 반품의 요청이 정당하지 않다고 판단하면 구매자의 의사표시를 취소하여 보관 중인 결제대금을 판매자에게 지급할 수 있습니다. 또한, 구매자의 교환, 반품 등이 접수된 날로부터 14일이 지날 때까지 이미 수령한 상품을 판매자에게 반환하지 않거나 전화, 이메일(E-mail) 등으로 연락되지 않으면 교환, 반품의 의사를 철회한 것으로 간주하여 보관 중인 결제대금을 판매자에게 지급할 수 있습니다.
⑩ 확인된 거래가 취소되어 결제대금을 환불할 경우는 회사는 거래가 취소된 날로부터 2영업일 이내에 구매자에게 환불에 필요한 조치를 합니다.
⑪ 카드결제로 구매한 건의 환불은 카드결제 취소를 통해서만 가능하며, 어떠한 경우에도 현금잔고로 환불되지 않습니다.
⑫ 회원은 상품이 발송되기 전까지 구매를 취소할 수 있으며, 배송 중이면 취소가 아닌 반품절차에 의해 처리됩니다.
⑬ 입금확인 상태에서는 즉시 취소처리가 완료되는 것이 원칙이나 이미 상품이 발송되었다면 발송된 상품의 왕복 배송비는 구매자가 부담해야 합니다.
※ 위 조항은 프라이스골프 주문에 한하여 우선 적용됩니다. 제휴사 주문은 제휴사의 반품조항을 최우선시 합니다.

제38조 [금지행위]
① 회사가 제공하는 결제대금 보호 서비스를 이용하지 않고 판매자와 구매자가 직접 거래하는 행위(직거래)는 거래의 안전을 위하여 금지되고, 직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 회사는 이에 대해 어떠한 책임도 지지 않습니다.
② 직거래를 하거나 이를 유도한 것으로 확인된 판매자는 서비스 이용이 정지되거나 이용계약이 해지될 수 있으며, 이용자는 직거래를 하거나 유도한 판매자를 회사가 운영하는 안전거래센터에 신고할 수 있습니다.
③ 회사가 제공하는 서비스 이용방법을 따르지 않고 비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위는 금지됩니다.
④ 시스템 부정행위가 확인되면 회사는 해당 회원에게 부가적으로 제공한 혜택의 일부 또는 전부를 회수하거나, 특정 서비스의 이용을 제한하고, 이용계약을 해지하는 등의 조치를 할 수 있습니다.
⑤ 타인의 명의, 카드정보, 계좌정보 등을 무단으로 사용하여 회사가 제공하는 구매서비스를 이용하는 행위는 금지됩니다.
⑥ 물품판매 또는 용역제공을 가장하여 자금을 융통하는 것과 같이 여신전문금융업법 등 법령에 따라 금지된 방법으로 비정상적인 결제를 하는 것은 금지되며, 해당 내용이 확인되었을 때 회사는 회원의 거래를 취소하거나 중지시키고 관련기관에 통보할 수 있습니다.
⑦ 회사는 구매회원의 부정 거래 행위(현금융통, 결제수단도용, 대량 허위거래 등)가 의심되면 일시적으로 서비스 이용을 정지하고 거래 사실 확인 및 소명을 위해 필요 최소한의 증빙자료(거래사실증명서, 물품 수급 및 발주, 배송증빙서류 등)를 요청 할 수 있습니다. 만약 특별한 사정없이 회사가 요청한 증빙자료를 정해진 기한 내에 제출하지 않거나 서류 미비 또는 소명이 되지 않을 경우 서비스 이용이 영구히 정지되거나 이용계약이 해지될 수 있습니다.
⑧ 회사는 실질적인 구매의사 없이 구매 후 취/반품을 반복하는 행위 등으로 판매자의 원활한 판매와 공정한 시장환경 조성을 방해하는 구매회원에 대하여 경고, 일정기간 이용정지, 영구 이용정지 등의 제재 조치를 취할 수 있습니다.
⑨ 회사는 구매회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 모니터링을 실시하고 있으며, 구매회원은 회사의 모니터링 업무에 대하여 협조하여야 하며 회사가 요청하는 필요 최소한의 증빙자료를 제출할 의무가 있습니다.

제39조 [안전거래센터]
① 회사는 타인의 권리 침해, 법령의 위반 등 회원이 서비스를 이용할 때 발생할 수 있는 거래의 안전과 관련된 법적 문제를 조정하기 위하여 안전거래센터를 운영합니다.
② 안전거래센터는 다음과 같은 역할을 합니다.
1. 매매부적합물의 판매에 대한 조치
2. 타인의 권리를 침해하는 행위에 대한 조치
3. 약관 위반 또는 법령을 위반하는 행위에 대한 조치
4. 기타 거래의 안전 및 사이트의 안전 확보등을 위한 조치
5. 회원 또는 거래의 안전 확인 및 안전이 확인될 때 까지 거래 보류하거나 거래 취소할 수 있으며, 서비스 이용을 제한하거나 이용계약의 해지 등의 조치
③ 안전거래센터의 조정에 회원은 신의성실의 원칙에 따라 성실히 응해야 합니다.

제40조 [분쟁조정센터]
① 회사는 회원 상호 간 또는 회원과 제3자 간의 분쟁을 조정하기 위하여 분쟁조정센터를 운영 합니다.
② 분쟁조정센터를 통하여 분쟁을 조정할 때 회사는 제3자의 입장에서 공정하게 분쟁의 조정에 임해야 합니다.
③ 분쟁조정센터의 조정에 회원은 신의성실의 원칙에 따라 성실히 응해야 합니다.

제41조 [회사의 면책]
① 상품의 매매와 관련하여 발생하는 상품의 배송, 청약철회, 반품 등의 거래진행은 거래 당사자인 판매자와 구매자 간에 수행되어야 하며, 회사는 거래진행에 관여하지 않습니다.
② 제13조 제2항의 사유로 서비스를 일시적으로 중단해야 할 경우 회사는 사이트를 통해 공지하며, 이는 서비스 일시 중단이 불가피하거나 불가항력적인 경우이므로 이용자 또는 제3자에게 손해가 발생했더라도 책임을 부담하지 않습니다.
③ 제13조 제3항의 사유로 서비스를 제한하거나 중단하게 된다면 회사는 불가항력을 이유로 그 책임을 지지 않습니다.
④ 회사는 이용자의 귀책사유로 서비스 이용에 장애가 발생하는 것에 대해 책임을 지지 않습니다.
⑤ 이용자가 자신의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
⑥ 회사의 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(이하 피연결회사)와 이용자간에 이루어진 거래에 회사는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다.
⑦ 회사는 서비스 화면에 표시되는 판매자 또는 제3자가 제공하는 상품 또는 정보 등의 정확성, 적시성, 타당성 등을 보증하지 않습니다.
⑧ 회사는 제29조 제1항에 따른 서비스나 기능의 전부 또는 일부를 수정하거나 중단하는 것에 대해 책임을 지지 않습니다.

제5장 개인정보보호

제42조 [회사의 개인정보 보호의무]
① 회사는 회원이 안심하고 회사의 서비스를 이용할 수 있도록 노력합니다.
② 회사는 개인정보보호와 관련하여 문제점이 발생했을 경우엔 적절한 조치를 취하여 재발되지 않도록 노력합니다.

제43조 [회원아이디 및 비밀번호의 관리]
① 회원은 회원아이디 및 비밀번호를 스스로의 책임하에 관리해야 합니다. 또한 피싱 등 사회공학적 방법에 의한 개인정보 무단수집으로부터 자신의 개인정보를 책임있게 관리하여야 합니다.
② 회원은 회원아이디 및 비밀번호를 타인에게 양도하거나 사용하게 할 수 없습니다.
③ 회원은 다음 각호의 경우를 인지하는 즉시 회사에 통지해야 하고 회사의 안내가 있는 경우 그에 따라야 합니다.
1. 회원아이디 또는 비밀번호를 도난당한 경우
2. 제3자가 회원본인의 ID 및 비밀번호를 사용하고 있음을 인지한 경우
④ 회원의 고의 또는 과실로 인한 회원아이디 또는 비밀번호의 유출 및 사용 승낙으로 인한 모든 책임은 회원에게 있습니다.

제44조 [개인정보의 수집, 이용 등]
① 회사는 서비스의 원활한 제공 및 동서비스의 기능 확대 목적으로 회원의 개인정보를 수집, 이용할 수 있습니다.
② 본조 제1항의 목적으로 수집, 이용할 수 있는 정보는 다음 각호와 같습니다.
1. 성명(실명)
2. 주소
3. 연락처
4. 회원아이디(ID)
5. 비밀번호
6. 거래은행명 및 계좌번호
③ 회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
④ 회사는 이용자의 동의 없이 해당 정보를 본조 제1항의 목적 외로 이용하거나, 제3자에게 제공할 수 없습니다. 단, 다음 각호의 경우는 예외로 합니다.
1. 배송업체에게 배송업무상 필요한 최소한의 이용자 정보(성명, 주소, 연락처)를 알려주는 경우
2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 가공, 제공하는 경우
3. 관계법령에서 정보의 공개를 요구한 경우
4. 법령에 의하여 정보의 제공이 가능한 경우
⑤ 회사가 본조 제3항에 의해 회원의 동의를 받아 당해 정보를 수집하는 경우에는 다음 각호의 사항을 명시하여 회원의 동의를 받아야 합니다.
1. 개인정보 관리책임자의 소속(성명, 소속, 연락처)
2. 정보 수집목적 및 이용목적
3. 제3자에 대한 정보제공인 경우 관련사항(제공받는 자, 제공 목적 및 제공할 정보의 내용)
⑥ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인 정보를 지체 없이 파기합니다.
⑦ 회원은 본조 제3항 및 제5항에 의한 동의를 언제든지 철회 할 수 있습니다.

제45조 [개인정보열람요청 등]
① 회원은 언제든지 회사가 보관하는 본인정보의 열람 및 오류의 정정을 요청할 수 있습니다.
② 회사는 본조 제1항의 요청이 있을 경우 지체 없이 필요한 조치를 취해야 합니다.
③ 회원의 오류정정 요청이 있을 경우, 회사는 그 오류를 정정 할 때까지 당해 개인정보를 이용하지 않습니다.

제46조 [개인정보 보유 등]
① 회사는 회원의 별도의 요구가 없을 때에는 회원의 정보를 안전하게 보관합니다.
② 회사는 회원의 요구가 있는 경우 회원의 개인정보를 삭제하거나 유지할 수 있습니다.
③ 제21조 [자진탈퇴] 및 제23조 [강제탈퇴]에 해당하는 회원은 회사의 서비스를 이용할 수 없습니다.
④ 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다.
1) 회원가입정보의 경우 회원가입을 탈퇴하거나 회원에서 제명된 경우 등 일정한 사전에 보유목적, 기간 및 보유하는 개인정보 항목을 명시하여 동의를 구합니다.
① 계약 또는 청약철회 등에 관한 기록 : 5년
② 대금결제 및 재화 등의 공급에 관한 기록 : 5년
③ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
④ 회사는 서비스를 12개월 이상 사용하지 않는 회원의 개인정보를 당해 회원 동의 하에 삭제할 수 있습니다.


제6장 기타사항

제47조 [금지행위]
① 회원은 다음 각호의 행위를 해서는 안됩니다.
1. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반 하는 정보를 사이트에 공개 또는 게시하는 행위
2. 사이트에 게시된 정보의 변경
3. 회사 기타 제3자의 저작권 등 지적재산권에 대한 침해
4. 사이트 운영의 방해
5. 회사 기타 제3자의 명예를 훼손하거나 업무를 방해하는 행위
6. 기타 회사가 부적절하다고 판단하는 행위
② 회사는 본조 제1항 각호의 정보 기타 회사 운영상 부적절하다고 판단한 정보가 회사에 게시되거나 회사와 링크된 곳에 게시된 경우, 회원 기타 정보를 게시한 자의 승낙 없이 회사에 게재된 당해 정보를 삭제하거나 회사에 확장된 링크를 절단할 수 있습니다.
③ 회사는 본조 제2항으로 인한 회원의 손해는 책임지지 않습니다.

제48조 [기타조항]
① 회사는 필요하면 특정 서비스나 기능의 전부 또는 일부를 일시적 또는 영구적으로 수정하거나 중단할 수 있습니다.
② 각 당사자는 상대방의 서면 동의 없이 이 약관상의 권리와 의무를 제3자에게 양도하거나 처분할 수 없습니다.
③ 이 약관과 관련하여 당사자 간의 합의에 따라 추가로 작성된 계약서, 협정서, 통보서 등과 회사의 정책변경, 법령의 제·개정 또는 공공기관의 고시나 지침 등에 따라 회사가 사이트를 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
④ 회사는 구매자 회원이 서비스를 이용할 때 발생할 수 있는 정당한 의견이나 불만 사항을 적극적으로 수렴하여 해결하고, 회원 상호 간의 분쟁을 조정하기 위하여 고객센터를 설치하고 운영합니다. 회사는 회원이 제기하는 각종 불만사항과 의견을 적극적으로 검토한 후 정당하다고 판단될 때에는 이를 신속하게 처리하며, 즉시 처리하기가 어려운 사항이 있으면 그 사유와 처리기간을 이메일(E-mail) 혹은 전화로 회원에게 통보 합니다.

제49조 [준거법 및 관할법원]
① 이 약관과 회사와 회원 간의 서비스 이용 계약, 회원 상호 간의 물품거래에는 대한민국 법령이 적용됩니다.
② 이 서비스와 관련하여 회사와 이용자 사이에 분쟁이 발생하여 소송이 제기되었을 때에는 민사소송법에 따라 관할법원을 정합니다.

부칙
A. 판매거부
단계별 조치사항
1회 : 경고조치 후 7일 상품등록 제한
2회 : 경고조치 후 14일 상품등록 제한
3회 : 경고조치 후 21일 상품등록 제한
4회 : 경고조치 후 28일 상품등록 제한
5회 : 30일 상품등록 제한 및 불량 판매자로 등록
- 판매거부에 대한 조치사항은 사안에 따라 가감될 수 있습니다.

B. 직거래 유도 및 가품(상표권/저작권법 위반상품)판매
단계별 조치사항
1회 : 상품등록 제한 및 불량판매자 등록
2회 이상 : 영구 정지 및 회원 박탈 및 경찰청 ,검찰청 등 사법기관에 고발

C. 비정상적 거래/부정거래(카드)
단계별 조치사항
1회 : 아이디 영구정지 및 진행중인 중개철회
2회 이상 : 회원 영구정지 및 경찰청 ,검찰청 등 사법기관에 고발`,
    },
    {
      title: "가품판매금지약관",
      type: "fakeSales",
      text: `가품 거래의 책임
- 가품 등록이나 거래로 발생되는 법적 책임 및 손해 배상은 판매자 책임을 명시한다.

가품 판매로 인한 손해배상
-가입 판매자의 가품 또는 정품미확인 상품의 판매가 제휴연동채널(지마켓,옥션,11st,인터파크,스마트스토어,쿠팡 등)의 '위조상품 판매자 정책' 위반을 초래하여 프라이스골프에 피해를 입혔을 경우, 해당 가품판매자는 프라이스골프가 입은 손해액의 200%를 배상한다.

가품 판매시 처벌조항
- 상표법위반의 범죄행위로서 7년이하의 징역 1억원 이하의 벌금형에 처해집니다.(상표법 93조)
- 모조품임을 모르고 판매한 경우도 처벌을 받으며, 알고 판매한 경우는 사기죄의 적용도 받습니다.
- 신상이 공개된 시장에서 모조품을 판매하는 것은 경찰수사대상 일순위를 자처하는 행위입니다.
- 모조품 판매자는 프라이스골프의 영업을 방해하는 자로 간주하여 회원삭제, 경찰에 고발합니다.
- 판매자의 모든 로그자료는 회원탈퇴 후 6개월까지 프라이스골프에 남아 고발 자료로 사용됩니다.

가품이 확인되었을 시 이유 불문 발생하는 모든 책임은 판매자에게 있습니다.`,
    },
    {
      title: "직거래금지약관",
      type: "directTrade",
      text: `직거래 금지에 관한 고지
- 직거래 유도행위를 할경우 프라이스골프에서 판매를 하실 수 없습니다.
- 직거래 유도행위에는 연락처기재, 직거래요청, 소액의 결제창거래후 구매자와 연락을 통한 직거래 등의 행위가 포함됩니다.
- 직거래로 인한 피해는 프라이스골프에서는 전혀 책임을 지지 않습니다.`,
    },
    {
      title: "판매규정준수 약관",
      type: "salesRules",
      text: `프라이스 골프 판매규정 고지
- 부당한 판매거부시에는 판매상의 제한을 당할 수 있습니다.
- 판매자는 전자상거래법과 프라이스골프의 판매규정(약관)을 준수하셔야 합니다.
- 수수료체계 : 현금판매 or 카드판매시 15%~10%의 수수료가 발생됩니다.(수수료는 향후 변경될 수 있습니다.)
- 수수료율은 판매자의 판매실적에 따라 책정됩니다.
- 수수료구분 : 판매자 전체 부담.
- 규정에 위배되는 판매행위에 대해서는 별도의 경고없이 판매정지 처리합니다.
- 규정과 반하여 주문처리를 강행할경우 프라이스골프에서 규정에 따라 임의처리를 할 수 있습니다.
- 영업 목적의 판매는 사업자등록이 필요합니다. 미등록시 관련법에 의거 추징, 징역, 벌금형에 처할 수 있습니다.
- 등록된 글은 중고시세표의 시세에 반영되며 가격 이외의 정보는 이용되지 않습니다.
- 등록된 글의 삭제는 판매상의 기록 등의 이유로 불가능합니다.
`,
    },
  ],
  allowZeroExpanded: true,
} as const;

export const BANK = [
  "국민은행",
  "기업은행",
  "농협중앙회",
  "단위농협",
  "신한은행",
  "우리은행",
  "카카오뱅크",
  "하나은행",
  "SC제일은행",
  "강원은행",
  "경남은행",
  "광주은행",
  "대구은행",
  "도이치은행",
  "미쓰비시도쿄은행",
  "바로저축은행",
  "부산은행",
  "산림조합",
  "상업은행",
  "상호저축은행",
  "새마을금고",
  "서울은행",
  "수협중앙회",
  "신용협동조합중앙회",
  "외환은행",
  "우체국",
  "전북은행",
  "제주은행",
  "조흥은행",
  "축협중앙회",
  "케이뱅크",
  "토스뱅크",
  "평화은행",
  "한국산업은행",
  "한국씨티은행",
  "한미은행",
  "한일은행",
  "홍콩상하이은행",
  "ABN암로",
  "BOA",
  "JP모건",
  "교보증권",
  "대신증권",
  "동부증권",
  "메리츠증권",
  "미래에셋증권",
  "부국증권",
  "삼성증권",
  "신영증권",
  "신한금융투자",
  "유안타증권",
  "유진투자증권",
  "이트레이드",
  "키움증권",
  "하나금융투자",
  "하이투자증권",
  "한국투자증권",
  "한화투자증권",
  "KB증권",
  "LIG증권",
  "NH투자증권",
  "SK증권",
];

export const COURIER = [
  "로젠택배",
  "CJ대한통운",
  "KGB택배",
  "한진택배",
  "롯데(현대)택배",
  "드림택배(KG로지스)",
  "GS포스트박스",
  "CU편의점택배",
  "일양로지스",
  "우체국택배",
  "합동택배",
  "대신택배",
  "건영택배",
  "천일택배",
  "HPL한의사랑택배",
  "홈픽택배",
  "DHL",
  "EMS",
  "FedEx",
  "TNT익스프레스",
  "UPS",
  "기타발송",
  "직접전달",
  "퀵서비스",
];

export const COURIER_2 = [
  { value: "CJGLS", name: "CJ대한통운" },
  { value: "CVSNET", name: "GS포스트박스" },
  { value: "DHL", name: "DHL" },
  { value: "EMS", name: "EMS" },
  { value: "FEDEX", name: "FedEx" },
  { value: "HPL", name: "HPL한의사랑택배" },
  { value: "KGBOLD", name: "KGB택배" },
  { value: "DREAM", name: "드림택배(KG로지스)" },
  { value: "TNT", name: "TNT익스프레스" },
  { value: "UPS", name: "UPS" },
  { value: "KUNYOUNG", name: "건영택배" },
  { value: "KDEXP", name: "경동택배" },
  { value: "DAESIN", name: "대신택배" },
  { value: "KGB", name: "로젠택배" },
  { value: "EPOST", name: "우체국택배" },
  { value: "ILYANG", name: "일양로지스" },
  { value: "CHUNIL", name: "천일택배" },
  { value: "HANJIN", name: "한진택배" },
  { value: "HDEXP", name: "합동택배" },
  { value: "HYUNDAI", name: "롯데(현대)택배" },
  { value: "CUPARCEL", name: "CU편의점택배" },
  { value: "HOMEPICK", name: "홈픽택배" },
  { value: "PIEX", name: "팬스타국제특송" },
  { value: "LX", name: "LX판토스" },
  { value: "QUICK", name: "퀵서비스" },
  { value: "DIRECT", name: "직접전달" },
  { value: "ETC", name: "기타발송" },
];

export const RETURN_REASON = [
  "상품에 이상이 없으나 구매 의사 없어짐",
  "스펙/사이즈/색상등을 잘못 선택함",
  "상품이 상세정보와 다름",
  "다른 상품이 잘못 배송됨",
  "배송된 상품의 파손/하자/불량",
  "상품이 도착하지 않음",
];

export const EXCHANGE_REASON = [
  "다른 상품으로 교환",
  "스펙/사이즈/색상등을 잘못 선택함",
  "상품이 상세정보와 다름",
  "잘못된 상품이 배송됨",
  "배송된 상품의 파손/하자/불량",
];

export const ORDER_CANCEL_REASON = [
  "구매 의사 없어짐",
  "상품정보를 잘못 보고 구매함",
  "다른 상품을 구매할 예정",
  "주문상품의 품절/재고없음",
  "판매자가 품절 통보",
  "판매자가 직거래유도 함",
];
