import { queryClient } from "app/appQueryClient";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CallApi from "shared/api/CallApi";
import { baseQueryKeys } from "shared/constants/baseQueryKeys";
import {
  BUG_REPORT_OPTIONS,
  CUSTOMER_INQUIRIES_OPTIONS,
  FAKE_REPORT_OPTION,
  PRODUCT_QUERY_OPTIONS,
  UNSAFE_TRANSACTIONS_OPTIONS,
} from "shared/constants/Etc";
import Swal from "sweetalert2";
import { LegacyContainedBtn } from "../../../zLegacyAtom/Buttons/ContainedBtn";
import { LegacyOutlinedBtn } from "../../../zLegacyAtom/Buttons/OutlinedBtn";
import { LegacyCheckBox } from "../../../zLegacyAtom/Inputs/CheckBox/CheckBox";
import { DefaultHeader } from "../../Headers/DefaultHeader";
import { LoadingPage } from "../../Loading/LoadingPage";
import "../style/RegisterBoardLayout.scss";
import { ImageUploadManager } from "./ImageUploadManager";

interface ICaptureImage {
  img: string;
  key: string;
}

interface IFormInput {
  category?: number | string;
  title: string;
  content: string;
  secret: boolean;
  images?: Array<string>;
  ncapchaKey: string;
  ncapchaValue: string;
  productCode?: string; // 불건전거래신고, 가품신고
}

export const RegisterBoardLayout = ({ title }: { title: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const abortController = new AbortController();
  const { pathname, search } = useLocation();
  const { productId } = useParams();
  const { boardId, productCode, requestMode } = qs.parse(
    search.slice(1, search.length)
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
    setFocus,
    getValues,
  } = useForm<IFormInput>({
    mode: "onChange", // keyup과 같은 효과
  });
  const [captchaImage, setCaptchaImage] = useState<ICaptureImage>({
    img: "",
    key: "",
  });
  // 첨부파일 이미지
  const [attachedImages, setAttachedImages] = useState<string[]>([]);

  // 자동입력방지 이미지
  const requestCaptchaImage = async () => {
    setValue("ncapchaValue", "");
    const res = await CallApi.request(
      "/api/v1/captcha/image",
      "GET",
      null,
      null,
      abortController.signal
    );
    if (res?.responseType === "SUCCESS") {
      setCaptchaImage(res.result);
      setValue("ncapchaKey", res.result.key);
    }
  };

  useEffect(() => {
    requestCaptchaImage();

    // 가품신고 카테고리 값 고정.
    if (pathname.includes("fake")) {
      setValue(
        "category",
        "⑫ 유명브랜드 유사상품,위조상품, 가짜(짝퉁)상품이 확실한 경우"
      );
      setValue(
        "title",
        "⑫ 유명브랜드 유사상품,위조상품, 가짜(짝퉁)상품이 확실한 경우"
      );
    }

    // 게시글 수정인 경우 (기존 데이터 받아오기)
    if (requestMode === "edit") {
      (async () => {
        const { boardList, inquiryList } = await CallApi.request(
          `${boardPath()}/${boardId}`,
          "GET",
          null,
          null,
          abortController.signal
        );
        queryClient.invalidateQueries([baseQueryKeys.help]);

        // 상품문의(고객문의)
        if (inquiryList) {
          setValue("title", inquiryList[0]?.title);
          setValue("content", inquiryList[0]?.content);
          setValue("secret", inquiryList[0]?.secret);
          const changeToOptionName = () => {
            const element = CUSTOMER_INQUIRIES_OPTIONS?.find(
              ele => ele.optionValue === inquiryList[0]?.inquiryType
            );

            return element?.optionName;
          };
          setValue("category", changeToOptionName());
        }
        // 고객센터 게시판(버그에러, 불건전거래, 가품)
        else if (boardList) {
          setValue("title", boardList[0]?.title);
          setValue("content", boardList[0]?.content);
          setValue("secret", boardList[0]?.secret);

          // if (boardList[0].images) {
          //   if (Array.isArray(boardList[0].images) && boardList[0].images[0]) {
          //     setImageFile1({ path: "", url: boardList[0].images[0] });
          //   }
          //   if (Array.isArray(boardList[0].images) && boardList[0].images[1]) {
          //     setImageFile2({ path: "", url: boardList[0].images[1] });
          //   }
          //   if (Array.isArray(boardList[0].images) && boardList[0].images[2]) {
          //     setImageFile3({ path: "", url: boardList[0].images[2] });
          //   }
          // }

          if (pathname.includes("fake")) {
            setValue(
              "category",
              "⑫ 유명브랜드 유사상품,위조상품, 가짜(짝퉁)상품이 확실한 경우"
            );
          } else {
            setValue("category", boardList[0]?.category);
          }
        }
      })();
    }
    return () => abortController.abort();
  }, []);

  // onSubmit
  const onSubmit: SubmitHandler<IFormInput> = async data => {
    setIsLoading(true);
    const isCapchaValid = await CallApi.request(
      "/api/v1/captcha/result",
      "POST",
      "",
      {
        key: captchaImage.key,
        value: data.ncapchaValue,
      }
    );

    // 신규등록 POST Data
    const formData = () => {
      if (pathname.includes("/illegal-deal")) {
        return {
          ...data,
          productCode,
          title: data.title,
          images: attachedImages,
        };
      }
      if (pathname.includes("/fake")) {
        return { ...data, productCode, images: attachedImages };
      }
      if (pathname.includes("/help/qna")) {
        delete data.category;

        const changeToOptionValue = () => {
          const element = CUSTOMER_INQUIRIES_OPTIONS?.find(
            ele => ele?.optionName === getValues("category")
          );
          return element?.optionValue;
        };

        return {
          ...data,
          category: changeToOptionValue(),
          images: attachedImages,
        };
      }
      if (pathname.includes("/qna") && pathname.includes("/products")) {
        delete data.category;

        const changeToOptionValue = () => {
          const element = PRODUCT_QUERY_OPTIONS?.find(
            ele => ele?.optionName === getValues("category")
          );

          return element?.optionValue;
        };

        return {
          ...data,
          category: changeToOptionValue(),
          images: attachedImages,
        };
      }
      return { ...data, images: attachedImages };
    };

    // 수정 PUT Data (기존 첨부 이미지 수정기능 X )
    const editFormData = () => {
      if (pathname.includes("illegal-deal")) {
        return {
          ...data,
          productCode,
          title: data.title,
        };
      }
      if (pathname.includes("fake")) {
        delete data.category;
        return {
          ...data,
          productCode,
        };
      }
      if (pathname.includes("/help/qna")) {
        const changeToOptionValue = () => {
          const element = CUSTOMER_INQUIRIES_OPTIONS?.find(
            ele => ele.optionName === data.category
          );
          return element?.optionValue;
        };
        return {
          ...data,
          category: changeToOptionValue(),
        };
      }
      return data;
    };

    if (isCapchaValid?.result) {
      // 신규등록
      if (requestMode === "new") {
        const res = await CallApi.request(
          boardPath(),
          "POST",
          null,
          formData()
        );

        if (res) {
          if (res?.responseType === "SUCCESS") {
            // 게시글 등록 성공 후 경로 이동 설정
            if (pathname.includes("illegal-deal")) {
              Swal.fire({
                text: "등록되었습니다.",
                confirmButtonText: "확인",
                allowOutsideClick: false,
              }).then(function (result) {
                if (result.isConfirmed) {
                  navigate("/help/illegal-deal");
                }
              });
            } else if (pathname.includes("fake")) {
              Swal.fire({
                text: "등록되었습니다.",
                confirmButtonText: "확인",
                allowOutsideClick: false,
              }).then(function (result) {
                if (result.isConfirmed) {
                  navigate("/help/fake");
                }
              });
            } else {
              Swal.fire({
                text: "등록되었습니다.",
                confirmButtonText: "확인",
                allowOutsideClick: false,
              }).then(function (result) {
                if (result.isConfirmed) {
                  navigate(-1);
                }
              });
            }
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
      // 수정
      else if (requestMode === "edit") {
        const res = await CallApi.request(
          `${boardPath()}/${boardId}`,
          "PUT",
          null,
          editFormData()
        );

        if (res?.responseType === "SUCCESS") {
          Swal.fire({
            text: "수정되었습니다.",
            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then(function (result) {
            if (result.isConfirmed) {
              navigate(-1);
            }
          });
        } else {
          setIsLoading(false);
        }
      }
    } else if (!isCapchaValid?.result) {
      Swal.fire({
        text: "자동등록방지 문자를 정확히 입력해주세요.",
        confirmButtonText: "확인",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          setValue("ncapchaValue", "");
          requestCaptchaImage();
          setFocus("ncapchaValue");
          setIsLoading(false);
        }
      });
    }
  };

  // select option list
  const selectList = useCallback(() => {
    if (pathname.includes("help/qna")) {
      return CUSTOMER_INQUIRIES_OPTIONS;
    }
    if (pathname.includes("help/report")) {
      return BUG_REPORT_OPTIONS;
    }
    if (pathname.includes("help/illegal-deal")) {
      return UNSAFE_TRANSACTIONS_OPTIONS;
    }
    if (pathname.includes("help/fake")) {
      return FAKE_REPORT_OPTION;
    }
    if (pathname.includes("products")) {
      return PRODUCT_QUERY_OPTIONS;
    }
  }, [pathname]);

  // api path
  const boardPath = useCallback(() => {
    if (pathname.includes("help/qna")) {
      return `/api/v1/help/customer-inquiries`;
    }
    if (pathname.includes("help/report")) {
      return `/api/v1/help/bugreport`;
    }
    if (pathname.includes("help/illegal-deal")) {
      return `/api/v1/help/illegaldeal`;
    }
    if (pathname.includes("help/fake")) {
      return `/api/v1/help/fake`;
    }
    if (pathname.includes("products")) {
      return `/api/v1/products/${productId}/inquiry`;
    }
    return "";
  }, [pathname]);
  const disabled =
    (requestMode === "edit" && pathname.includes("/help/report")) ||
    (requestMode === "edit" && pathname.includes("/help/illegal-deal")) ||
    (requestMode === "edit" && pathname.includes("/help/qna"));

  return (
    <>
      <DefaultHeader Title={title} rightIcon="list" backRoute={-1} />
      <div id="RegisterBoardLayout">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* 가품신고(fake)에서는 셀렉트 박스 노출 X, 타이틀로만 고정값 설정 */}
          {/* 불건전거래신고(illegal-deal)에서는 셀렉트 박스 inputName을 title로 설정 , 나머지 게시글의 inputName은 category로 설정 */}
          {/* 버그에러신고(report) & 고객문의(qna)에서는 category 셀렉트 박스와 title 인풋박스 둘다 노출 */}
          {!pathname.includes("/help/fake") && (
            <div className="wrap">
              <div id="Select">
                <select
                  {...register(
                    pathname.includes("/help/illegal-deal")
                      ? "title"
                      : "category",
                    {
                      required: true,
                    }
                  )}
                  defaultValue=""
                  disabled={disabled}
                >
                  <option value="">문의 카테고리를 선택해주세요.</option>
                  {selectList()?.map(({ optionName, optionValue }, idx) => (
                    <option
                      value={optionName}
                      key={idx}
                      disabled={
                        typeof optionValue === "string" &&
                        optionValue?.includes("--")
                      }
                    >
                      {optionName}
                    </option>
                  ))}
                </select>
              </div>

              {isSubmitted &&
                errors.title?.type === "required" &&
                pathname.includes("/help/illegal-deal") && (
                  <p className="validationErrorMessage">
                    문의 카테고리를 선택해주세요.
                  </p>
                )}
              {errors.category?.type === "required" &&
                !pathname.includes("/help/illegal-deal") && (
                  <p className="validationErrorMessage">
                    문의 카테고리를 선택해주세요.
                  </p>
                )}
            </div>
          )}

          {!pathname.includes("help/illegal-deal") && (
            <div className="wrap">
              <input
                type="text"
                id=""
                placeholder="제목을 입력해주세요"
                {...register("title", { required: "제목을 입력해주세요" })}
                disabled={pathname.includes("/help/fake")}
              />
              {errors.title && (
                <p className="validationErrorMessage">{errors.title.message}</p>
              )}
            </div>
          )}
          <div className="wrap">
            <textarea
              className="text_area"
              placeholder="문의사항을 상세히 작성해주세요"
              rows={13}
              {...register("content", {
                required: "문의사항을 상세히 작성해주세요",
              })}
            />

            {errors.content?.type === "required" && (
              <p className="validationErrorMessage">{errors.content.message}</p>
            )}
          </div>

          {pathname.includes("qna") || pathname.includes("products") ? (
            <div className="select_wrap">
              <p>보기설정</p>
              <LegacyCheckBox
                label="비밀글"
                register={register}
                inputName="secret"
                onChange={(e: any) => setValue("secret", e.target.checked)}
              />
            </div>
          ) : (
            <div className="select_wrap hidden">
              <LegacyCheckBox
                className=""
                label="비밀글"
                register={register}
                inputName="secret"
                onChange={(e: any) => setValue("secret", e.target.checked)}
                checked
              />
            </div>
          )}
        </form>

        {requestMode === "new" && !pathname.includes("products") && (
          <ImageUploadManager
            title="이미지 미리보기"
            setAttachedImages={setAttachedImages}
            setIsLoading={setIsLoading}
          />
        )}

        <div className="prevent_auto">
          <p>자동등록방지</p>
          <div className="wrap">
            <img
              className="validationImg"
              src={captchaImage.img}
              alt="Captcha"
            />
            <LegacyOutlinedBtn
              type="small"
              text="새로고침"
              onClick={requestCaptchaImage}
            />
          </div>
          <p>이미지의 숫자를 입력해주세요</p>
          <input
            id="captchaInput"
            type="text"
            {...register("ncapchaValue", {
              required: "이미지의 숫자를 입력해주세요",
            })}
          />
          {errors?.ncapchaValue?.type === "required" && (
            <p className="validationErrorMessage">
              {errors.ncapchaValue.message}
            </p>
          )}
        </div>

        <ul className="text13 graytext">
          <li>
            상품 Q&amp;A에 등록한 게시글은 마이페이지 &gt; 구매 &gt;
            구매문의관리에서도 확인하실 수 있습니다.
          </li>
          <li>
            부적절한 게시물 등록시 ID이용 제한 및 게시글이 삭제될 수 있습니다.
          </li>
          <li>
            - 전화번호, 이메일 주소 등 연락처를 기재하여 할인/직거래 등을 유도
          </li>
          <li>- 비방/욕설, 가격비교 정보, 물품과 관련 없는 광고글 등</li>
          <li>
            게시글에 회원님의 이메일, 휴대폰 번호와 같은 개인 정보의 입력은
            금지되어 있으며, 발생하는 모든 피해에 대해 프라이스골프는 책임지지
            않습니다.
          </li>
        </ul>
        <LegacyContainedBtn
          text={requestMode === "edit" ? "수정" : "등록"}
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      {isLoading && <LoadingPage spinner />}
    </>
  );
};
