import type { InputProps } from "..";
import Shared from "..";

export type InputLabelProps = Pick<
  InputProps,
  "error" | "children" | "id" | "style"
> & {
  label: string;
  errorMessage?: string;
};

export const InputLabel = ({
  label,
  style,
  id,
  error,
  errorMessage,
  children,
}: InputLabelProps) => {
  return (
    <label htmlFor={id} style={style}>
      <Shared.VStack fullWidth gap={6}>
        <span>{label}</span>

        {children}

        {error && (
          <span style={{ color: "var(--color-error-dark)" }}>
            ⚠ {errorMessage}
          </span>
        )}
      </Shared.VStack>
    </label>
  );
};
