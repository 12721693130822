import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TOP_NAV_PATH_LIST } from "shared/constants/Paths";
import { HomeHeader } from "../Headers/HomeHeader";
import "./TopScrollNavigation.scss";

interface navPath {
  name: string;
  path: string;
}
export const TopScrollNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const targetRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (targetRef && targetRef.current) {
      targetRef?.current?.scrollIntoView({
        inline: "center",
        behavior: "auto",
      });
    }
  }, [pathname]);

  const handleClick = (ele: navPath) => {
    navigate(ele.path);
  };

  return (
    <div id="TopNavigation">
      <HomeHeader />
      <nav id="ScrollNavTab">
        {TOP_NAV_PATH_LIST?.map((ele, idx) => (
          <span
            key={idx}
            className={
              ele.path === pathname || pathname.includes(`${ele.path}/`)
                ? "tab active"
                : "tab"
            }
            onClick={() => handleClick(ele)}
            ref={
              ele?.path === pathname ||
              ele?.path + "/model-price" === pathname ||
              ele?.path + "/shops" === pathname
                ? targetRef
                : null
            }
          >
            <img src={ele.img} alt={ele.name} />
            <span>{ele.name}</span>
          </span>
        ))}
      </nav>
    </div>
  );
};
