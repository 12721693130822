import type { FileUploadType } from "@service/file/fileService";
import classNames from "classnames";
import { useMemo, useRef } from "react";
import type { ReactQuillProps } from "react-quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Shared from "..";
import styles from "./Editor.module.scss";
import { useImageHandler } from "./useImageHandler";

export type EditorProps = ReactQuillProps & {
  imageUploadType: FileUploadType;
};

export const Editor = ({
  imageUploadType,
  value,
  onChange,
  ...rest
}: EditorProps) => {
  const quillRef = useRef<ReactQuill>(null);

  const { imageHandler, isLoading } = useImageHandler(
    quillRef,
    imageUploadType
  );

  /**
   * 툴바 설정
   * @see https://quilljs.com/docs/modules/toolbar
   */
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [imageHandler]
  );

  return (
    <div className={classNames(styles.Editor, isLoading && styles.isLoading)}>
      <ReactQuill
        ref={quillRef}
        modules={modules}
        value={value}
        onChange={onChange}
        readOnly={isLoading}
        theme="snow"
        {...rest}
      />

      {isLoading && (
        <div className={styles.loading}>
          <Shared.Spinner />
        </div>
      )}
    </div>
  );
};
