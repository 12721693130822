import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const ApplyCooperationBoard = lazy(() =>
  import("../../modules/boards/ApplyCooperation/ApplyCooperationBoard").then(
    module => ({
      default: module.ApplyCooperationBoard,
    })
  )
);
const ApplyCooperationPostDetail = lazy(() =>
  import(
    "../../modules/boards/ApplyCooperation/ApplyCooperationPostDetail"
  ).then(module => ({
    default: module.ApplyCooperationPostDetail,
  }))
);
const ApplyCooperationRegister = lazy(() =>
  import("../../modules/boards/ApplyCooperation/ApplyCooperationRegister").then(
    module => ({
      default: module.ApplyCooperationRegister,
    })
  )
);
const ApplyCooperationEdit = lazy(() =>
  import("../../modules/boards/ApplyCooperation/ApplyCooperationEdit").then(
    module => ({
      default: module.ApplyCooperationEdit,
    })
  )
);

export const ApplyCooperationRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <ApplyCooperationBoard />
          </Suspense>
        }
      />
      <Route
        path="/:boardId"
        element={
          <Suspense fallback={null}>
            <ApplyCooperationPostDetail />
          </Suspense>
        }
      />
      <Route
        path="/register"
        element={
          <Suspense fallback={null}>
            <ApplyCooperationRegister />
          </Suspense>
        }
      />
      <Route
        path="/:boardId/edit"
        element={
          <Suspense fallback={null}>
            <ApplyCooperationEdit />
          </Suspense>
        }
      />
    </Routes>
  );
};
