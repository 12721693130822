import type { FileUploadFormData } from "@service/file/fileService";
import { sizeToBytes, type FileSize } from "shared/lib/fileSizeConverter";
import { orientImageFile } from "shared/lib/imageProcessing";
import { showAlert } from "shared/lib/showAlert";
import { getTypedFormData } from "shared/lib/typedFormData";
import type { PreviewImage } from "../types";

// fileService로 옮기기
export const generateFormDataWithRotatedImages = async (fileList: File[]) => {
  const formData = getTypedFormData<FileUploadFormData>();

  const promises = fileList.map(async file => {
    const rotatedFile = await orientImageFile(file);
    if (rotatedFile) {
      formData.append("files", rotatedFile, file.name);
    }
  });
  await Promise.all(promises);

  return formData;
};

export const createImageList = (files: File[], id: number): PreviewImage[] =>
  files.map((file, seq) => ({
    id: `${id}_${seq}`,
    imagePath: URL.createObjectURL(file),
    seq,
    state: "loading",
  }));

export const handleUploadSuccess = (
  handleUpdateImage: (images: PreviewImage[]) => void,
  imageList: PreviewImage[],
  fileList: { fileUrl: string }[]
) =>
  handleUpdateImage(
    imageList.map(({ id }, i) => ({
      id,
      imagePath: fileList[i].fileUrl,
      state: "done",
    }))
  );

export const handleUploadError = (
  handleUpdateImage: (images: PreviewImage[]) => void,
  imageList: PreviewImage[],
  fileList: File[]
) =>
  handleUpdateImage(
    imageList.map(({ id }, i) => ({
      id,
      imagePath: fileList[i].name,
      state: "error",
    }))
  );

export const validateFileList = ({
  fileList,

  limit,
  maxCount,
  currentCount = 0,
}: {
  fileList: File[];
  limit?: FileSize;
  maxCount?: number;
  currentCount?: number;
}): boolean => {
  if (limit) {
    const overSizeFiles = fileList.filter(
      ({ size }) => size > sizeToBytes(limit)
    );

    if (overSizeFiles.length > 0) {
      showAlert(`파일용량은 ${limit}을 넘을 수 없습니다.`);
      return false;
    }
  }

  if (maxCount) {
    const count = fileList.length + currentCount;
    if (count > maxCount) {
      showAlert(`이미지는 최대 ${maxCount}개까지 업로드할 수 있습니다.`);
      return false;
    }
  }

  return true;
};
