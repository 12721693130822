import { LegacyOutlinedBtn } from "@components/zLegacyAtom/Buttons/OutlinedBtn";
import { LegacyCheckBox } from "@components/zLegacyAtom/Inputs/CheckBox/CheckBox";
import { RoundedCard } from "@components/zLegacyLayouts/Card/Card";
import "./styles/CheckboxAddrCard.scss";

export const CheckboxAddrCard = ({ data }: { data: any }) => {
  return (
    <>
      {data.map((data: any, idx: number) => (
        <div id="CheckboxAddrCard" key={idx}>
          <RoundedCard>
            <div className="header">
              <section>
                <LegacyCheckBox label={data.title} onChange={() => undefined} />
                {data.default && <span className="redtext">기본주소</span>}
              </section>
              <LegacyOutlinedBtn
                type="small"
                text="수정"
                link="/checkout/address/edit/24545433"
              />
            </div>
            <div className="content">
              <p>
                <span className="name">{data.name}</span>
                <span>{data.tel}</span>
              </p>
              <p>{data.addr}</p>
              <p className="request">
                <span className="graytext">배송요청사항</span>
                <span>{data.request}</span>
              </p>
            </div>
          </RoundedCard>
        </div>
      ))}
    </>
  );
};
