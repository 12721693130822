import { validateTokenTime } from "entities/user-info";
import { addTokensToCookies } from "features/auth";
import type { LoginServiceId, SignInResponse } from "features/auth/sign-in";
import { instance } from "shared/api/axios";
import { api } from "shared/constants/api";
import { COOKIE_KEYS } from "shared/constants/cookieKeys";
import { getCookie } from "shared/lib/legacyUtils/cookie";
import type { APIResponse, ResponseData } from "shared/types/server";
import {
  getKeepLoginCookie,
  isKeepLogin,
} from "../../features/auth/keep-login/lib/keepLogin";
import { userErrorHandlers } from "./errorHandlers";

export type KeepLoginPayload = {
  loginServiceId: LoginServiceId;
  id: string;
};

export type KeepLoginResponse = Pick<
  SignInResponse,
  "responseType" | "accessToken" | "refreshToken"
>;

export type SignUpPayload = {
  data: any;
  params: {
    number: string;
    key: string;
  } | null;
};

export const userService = {
  // 리프레시 토큰
  refreshToken: async () => {
    if (validateTokenTime("accessToken")) {
      return {
        responseType: "SUCCESS",
        accessToken: getCookie(COOKIE_KEYS.ACCESS_TOKEN),
        refreshToken: getCookie(COOKIE_KEYS.REFRESH_TOKEN),
      };
    }

    try {
      const {
        data: { data: response },
      } = await instance.post<APIResponse<KeepLoginResponse>>(
        api.users.refresh,
        {
          refreshToken: getCookie(COOKIE_KEYS.REFRESH_TOKEN),
        }
      );

      if (response.responseType === "SUCCESS") {
        addTokensToCookies(response.accessToken, response.refreshToken);
      }
      return response;
    } catch (e) {
      window.location.href = "/signin";

      console.log(e);
      throw e;
    }
  },

  // 로그인 유지 토큰 발급
  keepLogin: async (payload: KeepLoginPayload = getKeepLoginCookie()) => {
    if (!isKeepLogin()) return "off";

    try {
      const {
        data: { data: response },
      } = await instance.post<APIResponse<KeepLoginResponse>>(
        api.users.keepLogin,
        payload
      );
      if (response.responseType === "SUCCESS") {
        addTokensToCookies(response.accessToken, response.refreshToken);
      }
      return response;
    } catch (error: any) {
      const errorCode = error.response?.data?.data?.errorCode;
      const errorMessage = error.response?.data?.message;
      return userErrorHandlers.keepLogin(errorCode, errorMessage, error);
    }
  },

  // 회원가입
  signup: async ({ data, params }: SignUpPayload) => {
    const { data: response } = await instance.post<APIResponse<ResponseData>>(
      api.users.signup,
      data,
      { params }
    );
    return response.data;
  },
};
