import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// import { WriteCommentPost } from "../posts/QnaCommentPost.view";

const NoticeBoard = lazy(() =>
  import("../../modules/boards/notice/NoticeBoard.view").then(module => ({
    default: module.NoticeBoard,
  }))
);
const NoticeInquire = lazy(() =>
  import("../../modules/boards/notice/NoticeInquire.view").then(module => ({
    default: module.NoticeInquire,
  }))
);
export const HelpNoticeRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <NoticeBoard />
          </Suspense>
        }
      />
      <Route
        path=":noticeNum"
        element={
          <Suspense fallback={null}>
            <NoticeInquire />
          </Suspense>
        }
      />
      {/* <Route path="comment" element={<WriteCommentPost />} /> */}
    </Routes>
  );
};
