import classNames from "classnames";
import type { HTMLAttributes } from "react";
import styles from "./Divider.module.scss";

type Props = HTMLAttributes<HTMLDivElement> & {
  size?: "xxs" | "xs" | "s" | "m";
  fullWidthOffset?: boolean;
};

export const Divider = ({
  size = "m",
  fullWidthOffset,
  className,
  ...rest
}: Props) => {
  return (
    <div
      className={classNames(
        styles.Divider,
        styles[`size-${size}`],
        fullWidthOffset && styles.fullWidthOffset,
        className
      )}
      {...rest}
    />
  );
};
