import { getSellerLevelName, getTrustLevelName } from "entities/user-info";
import { useMemo } from "react";
import { formatPrice } from "shared/lib";
import type { DataListProps } from "shared/ui";
import Shared from "shared/ui";
import { useSellerInfo } from "../../../api/sellerInfoApi";
import styles from "./CurrentSalesPanel.module.scss";

export const CurrentSalesPanel = () => {
  const { data: sellerInfo, isLoading, isError, refetch } = useSellerInfo();

  const commissionInfo = sellerInfo?.commissionInfo;

  const baseData = useMemo<DataListProps["data"]>(() => {
    const productStatus = `${formatPrice(sellerInfo?.enrolledProductNum ?? 0)} / ${formatPrice(sellerInfo?.canEnrollProductNum ?? 0)}`;
    return [
      {
        title: "판매등급",
        value: (
          <span className="redtext font_500">
            {getSellerLevelName(sellerInfo?.sellerLevel)}
          </span>
        ),
      },
      {
        title: "신용등급",
        value: (
          <span className="redtext font_500">
            {getTrustLevelName(sellerInfo?.trustLevel)}
          </span>
        ),
      },
      {
        title: "등록현황",
        value: (
          <Shared.VStack>
            <span>{productStatus}</span>
            <Shared.LinkButton
              to="/myshop/sales/additional-services/products-register-unlimit"
              text="등록개수 늘리기"
              size="xs"
            />
          </Shared.VStack>
        ),
      },
      {
        title: "수수료율",
        value: `${sellerInfo?.commissionInfo?.finalCommissionRate}%`,
      },
    ];
  }, [
    sellerInfo?.canEnrollProductNum,
    sellerInfo?.commissionInfo?.finalCommissionRate,
    sellerInfo?.enrolledProductNum,
    sellerInfo?.sellerLevel,
    sellerInfo?.trustLevel,
  ]);

  const commissionDetails = useMemo<DataListProps["data"]>(
    () => [
      {
        title: "집계기간",
        value: `${commissionInfo?.startDate} ~ ${commissionInfo?.endDate}`,
      },
      {
        title: "기본 수수료율",
        value: `${commissionInfo?.baseCommissionRate}%`,
      },
      {
        title: "패널티 수수료율",
        value: `${commissionInfo?.penaltyCommissionRate}%`,
      },
      {
        title: "판매취소보정",
        value: `${commissionInfo?.staticCommissionRate}%`,
      },
    ],
    [commissionInfo]
  );

  const salesData = useMemo<DataListProps["data"]>(() => {
    const sellerSaleInfoIn6Month = commissionInfo?.sellerSaleInfoIn6Month;
    return [
      {
        title: "매출(6개월)",
        value: formatPrice(sellerSaleInfoIn6Month?.totalSellIn6Month ?? 0, {
          suffix: "원",
        }),
      },
      {
        title: "판매완료(6개월)",
        value: formatPrice(sellerSaleInfoIn6Month?.countSellIn6Month ?? 0, {
          suffix: "건",
        }),
      },
    ];
  }, [commissionInfo?.sellerSaleInfoIn6Month]);

  return (
    <Shared.VStack gap={10} className={styles.CurrentSalesPanel}>
      <Shared.DataList
        data={baseData}
        dtStyle={{ color: "#777777" }}
        dtWidth={70}
        rowGap={10}
        align="start"
        status={{
          isError,
          isLoading,
        }}
      />

      <div className={styles.commissionDetail}>
        <Shared.DataList
          data={commissionDetails}
          dtStyle={{ color: "#777777" }}
          rowGap={4}
          justify="between"
          status={{
            isError,
            isLoading,
          }}
        />
      </div>

      <Shared.DataList
        data={salesData}
        dtStyle={{ color: "#777777" }}
        dtWidth={120}
        rowGap={10}
        status={{
          isError,
          isLoading,
        }}
      />
      {isError && <Shared.Overlay.Error retryFunction={refetch} />}
    </Shared.VStack>
  );
};
