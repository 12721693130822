export const CATEGORY_SERVICE_PATH_LIST = [
  { name: "상품등록", path: "/products/register" },
  { name: "실시간 상품", path: "/real-time" },
  { name: "가격인하 상품", path: "/price-reduction" },
  { name: "중고시세표", path: "/used-price" },
  { name: "파워존", path: "/power-zone/products" },
  { name: "해외직구", path: "/overseas" },
  { name: "신상품샵", path: "/new" },
  { name: "고객센터", path: "/help" },
  { name: "제휴/입점 신청", path: "/apply-cooperation" },
  { name: "신규판매자 가이드", path: "/help/seller-guide" },
  { name: "직매입 견적의뢰", path: "/direct-purchase" },
  { name: "회사소개", path: "/aboutus" },
];

export const TOP_NAV_PATH_LIST = [
  { name: "홈", path: "/", img: "/assets/icons/icon_home.svg" },
  {
    name: "가격인하",
    path: "/price-reduction",
    img: "/assets/icons/icon_price_reduction.svg",
  },
  {
    name: "해외직구",
    path: "/overseas",
    img: "/assets/icons/icon_overseas.svg",
  },
  { name: "신상품", path: "/new", img: "/assets/icons/icon_new.svg" },

  {
    name: "파워존",
    path: "/power-zone/products",
    img: "/assets/icons/icon_powerzone.svg",
  },
  {
    name: "실시간",
    path: "/real-time",
    img: "/assets/icons/icon_realtime.svg",
  },
  { name: "시세표", path: "/used-price", img: "/assets/icons/icon_price.svg" },
  { name: "고객센터", path: "/help", img: "/assets/icons/icon_help.svg" },
  // { name: "상품팔기", path: "/products/register" },
];

export const TOP_NAV_PATH_LIST_FOR_SELLER = [
  { name: "홈", path: "/", img: "/assets/icons/icon_home.svg" },
  {
    name: "상품팔기",
    path: "/products/register",
    img: "/assets/icons/icon_sell.svg",
  },
  {
    name: "가격인하",
    path: "/price-reduction",
    img: "/assets/icons/icon_price_reduction.svg",
  },

  {
    name: "해외직구",
    path: "/overseas",
    img: "/assets/icons/icon_overseas.svg",
  },
  { name: "신상품", path: "/new", img: "/assets/icons/icon_new.svg" },

  {
    name: "파워존",
    path: "/power-zone/products",
    img: "/assets/icons/icon_powerzone.svg",
  },
  {
    name: "실시간",
    path: "/real-time",
    img: "/assets/icons/icon_realtime.svg",
  },
  { name: "시세표", path: "/used-price", img: "/assets/icons/icon_price.svg" },
  { name: "고객센터", path: "/help", img: "/assets/icons/icon_help.svg" },
];

export const CS_SECTION_PATH = [
  // { name: "공지사항", path: "/help/notice" },
  { name: "불건전거래 신고", path: "/help/illegal-deal" },
  { name: "가품 신고", path: "/help/fake" },
  { name: "버그에러 신고", path: "/help/report" },
];

export const QUICK_MENU = [
  {
    title: "마이페이지",
    path: { member: "/myshop", nonMember: "/signin" },

    icon: "/assets/icons/icon_user_l.svg",
  },
  {
    title: "주문/배송",
    path: { member: "/myshop/orders", nonMember: "/signin" },
    icon: "/assets/icons/icon_delivery_l.svg",
  },
  {
    title: "찜",
    path: { member: "/wishes/products", nonMember: "/signin" },
    icon: "/assets/icons/icon_favorite_l.svg",
  },
  {
    title: "고객센터",
    path: { member: "/help", nonMember: "/help" },
    icon: "/assets/icons/icon_call_l.svg",
  },
];
