import { useInfiniteQuery } from "@tanstack/react-query";
import { useAppSelector } from "app/appStore";
import { instance } from "shared/api";
import { api } from "shared/constants/api";
import type { APIResponse, ResponseData } from "shared/types/server";
import { sellerOrderQueryKey } from "./queryKey";
import type { SalesOrder } from "./types";
import { 판매요청_주문상태 } from "./types";

export type SalesPayload = {
  status: 판매요청_주문상태;
  /** 커서 (미입력시 1페이지) */
  lastCursor?: string;
  /** 조회 개수 (default: 10) */
  size?: number;
  /** 검색 날짜 연월일 (yyyymm 형식으로 6자리만) */
  searchYearMonth?: string;
};

type SalesOrderListResponse = ResponseData & {
  lastCursor: string;
  resultList: SalesOrder[];
};

const salesOrderApi = {
  /**
   * 판매 주문 목록 조회 (모바일웹)
   * @see https://sbridge.atlassian.net/wiki/x/AYANBQ
   */
  salesOrderList: async ({
    status = 판매요청_주문상태.전체,
    size = 10,
    lastCursor,
    searchYearMonth,
  }: SalesPayload) => {
    const {
      data: { data: response },
    } = await instance.get<APIResponse<SalesOrderListResponse>>(
      api.order.sales,
      {
        params: { status, lastCursor, searchYearMonth, size },
      }
    );

    return response;
  },
};

/** 판매 주문 목록 조회 */
export function useFetchSalesOrderList(payload: SalesPayload) {
  const { userId } = useAppSelector(state => state.user);
  return useInfiniteQuery({
    queryKey: sellerOrderQueryKey.salesOrderList(userId, payload),
    queryFn: ({ pageParam }) => {
      return salesOrderApi.salesOrderList({
        ...payload,
        lastCursor: pageParam,
      });
    },
    getNextPageParam: lastPage => {
      const { lastCursor, responseType } = lastPage;
      const hasNextPage = responseType === "SUCCESS" && Boolean(lastCursor);
      return hasNextPage && lastCursor;
    },
  });
}
