import { LegacyBackIcon } from "../../zLegacyAtom/LegacyIcons/BackIcon";
import { LegacyCartIcon } from "../../zLegacyAtom/LegacyIcons/CartIcon";
import { LegacyCloseIcon } from "../../zLegacyAtom/LegacyIcons/CloseIcon";
import { LegacyLogoIcon } from "../../zLegacyAtom/LegacyIcons/LogoIcon";
import { LegacySearchIcon } from "../../zLegacyAtom/LegacyIcons/SearchIcon";
import { Header } from "./Header";
import "./Header.scss";

type LeftIcon = "close" | "logo" | "back";
type RightIcon = "done" | "edit" | "wheel" | "cart" | "list" | "none";

const renderLeftIcon = (
  leftIcon: LeftIcon,
  route: any,
  backRoute: number | string
) => {
  switch (leftIcon) {
    case "close":
      return <LegacyCloseIcon route={route} />;
    case "logo":
      return <LegacyLogoIcon />;
    default:
      return <LegacyBackIcon />;
  }
};

const renderRightIcon = (rightIcon: RightIcon) => {
  switch (rightIcon) {
    case "done":
      return "완료";
    case "edit":
      return "편집";
    case "wheel":
      return "휠";
    case "cart":
      return <LegacyCartIcon />;
    case "list":
      return ""; // removed "목록" because isanim told
    default:
      return null;
  }
};

type Props = {
  logo?: boolean;
  Title: string;
  leftIcon?: LeftIcon;
  rightIcon?: RightIcon;
  searchIcon?: boolean;
  onPressed?: VoidFunction;
  route?: any;
  onClick?: any;
  setOpen?: any;
  backRoute?: number | string;
};

export const DefaultHeader = ({
  leftIcon = "back",
  rightIcon = "none",
  logo = true,
  Title,
  searchIcon,
  onPressed,
  route = -1,
  onClick,
  setOpen = null,
  backRoute = -1,
}: Props) => {
  return (
    <Header id="default_header">
      <div className="left">
        <div className="left1" onClick={() => setOpen?.(false)}>
          {renderLeftIcon(leftIcon, route, backRoute)}
        </div>
        {logo && (
          <div className="left2">
            <LegacyLogoIcon onClick={setOpen ? () => setOpen(false) : null} />
          </div>
        )}
      </div>
      <title>{Title}</title>
      <div className="right">
        <div className="right1">{searchIcon && <LegacySearchIcon />}</div>
        <div
          onClick={setOpen ? () => setOpen(false) : onPressed}
          className="right2"
        >
          {renderRightIcon(rightIcon)}
        </div>
      </div>
    </Header>
  );
};
