import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const SignIn = lazy(() =>
  import("../../legacyPages/sign/SignIn.view").then(module => ({
    default: module.SignIn,
  }))
);
const MemberTab = lazy(() =>
  import("../../modules/Sign/SignIn/MemberTab.module").then(module => ({
    default: module.MemberTab,
  }))
);
const DormantState = lazy(() =>
  import("../../legacyPages/sign/DormantState.view").then(module => ({
    default: module.DormantState,
  }))
);
export const SigninRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={null}>
            <SignIn />
          </Suspense>
        }
      >
        <Route
          path="/"
          element={
            <Suspense fallback={null}>
              <MemberTab />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="dormant"
        element={
          <Suspense fallback={null}>
            <DormantState />
          </Suspense>
        }
      />
    </Routes>
  );
};
