/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from "classnames";
import { forwardRef, type InputHTMLAttributes } from "react";
import styles from "./Switch.module.scss";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

export const Switch = forwardRef<HTMLInputElement, Props>(
  ({ className, disabled, checked, ...rest }, ref) => {
    return (
      <label
        className={classNames(
          styles.Switch,
          disabled && styles.disabled,
          checked && styles.checked,
          className
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          {...rest}
        />
      </label>
    );
  }
);
