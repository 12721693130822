import { Link } from "react-router-dom";
import { LegacyCartIcon } from "../../zLegacyAtom/LegacyIcons/CartIcon";
import { LegacyLogoIcon } from "../../zLegacyAtom/LegacyIcons/LogoIcon";
import { LegacySearchIcon } from "../../zLegacyAtom/LegacyIcons/SearchIcon";
import { LegacySellProductIcon } from "../../zLegacyAtom/LegacyIcons/SellProductIcon";
import { Header } from "./Header";
import "./HomeHeader.scss";

export const HomeHeader = () => {
  return (
    <Header className="home-header">
      <div className="left">
        <LegacyLogoIcon />
      </div>

      <Link to="/search" className="search-link">
        <h1 className="title">골프의 시작과 끝, &nbsp;프라이스골프</h1>
      </Link>

      <div className="right">
        <LegacySearchIcon />
        <LegacyCartIcon />
        <LegacySellProductIcon />
      </div>
    </Header>
  );
};
