import classNames from "classnames";
import styles from "./Badge.module.scss";

type Props = {
  text: string;
  size?: "s" | "m";
  colorVariant?: "red" | "gray" | "none";
};

export const Badge = ({ text, size = "m", colorVariant = "gray" }: Props) => {
  return (
    <div
      className={classNames(
        styles.Badge,
        styles[`size-${size}`],
        styles[`color-${colorVariant}`]
      )}
    >
      {text}
    </div>
  );
};
