import classNames from "classnames";
import { PickupStatus } from "entities/seller-order";
import Shared from "shared/ui";
import type { MessageProps } from "shared/ui/Message/Message";
import styles from "./ProductModules.module.scss";

type Props = Omit<MessageProps, "colorVariants" | "text"> & {
  className?: string;
  pickStatus: PickupStatus;
};

export const ProductPickStatus = ({
  className,
  pickStatus,
  ...rest
}: Props) => {
  const isCompleted = pickStatus === PickupStatus.회수신청완료;

  return (
    <Shared.Message
      className={classNames(styles.ProductPickStatus, className)}
      colorVariants={isCompleted ? "blue" : "error"}
      text={isCompleted ? "회수신청완료" : "회수보류"}
      {...rest}
    />
  );
};
