import classNames from "classnames";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLockBodyScroll } from "shared/hooks";
import Shared from "..";
import styles from "./BottomSheet.module.scss";
import { useBottomSheetTouchEvent } from "./useBottomSheetTouchEvent";

export type BottomSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  canSwipe?: boolean;
};

export const BottomSheet = ({
  isOpen,
  onClose,
  children,
  canSwipe = true,
}: BottomSheetProps) => {
  const [modalStatus, setModalStatus] = useState<"open" | "closing" | "closed">(
    isOpen ? "open" : "closed"
  );

  useLockBodyScroll(modalStatus !== "closed");

  const { handlers, resetTranslate, sheetRef } = useBottomSheetTouchEvent(
    onClose,
    { offset: 50, canSwipe }
  );

  useEffect(() => {
    if (isOpen) {
      setModalStatus("open");
    } else {
      setModalStatus("closing");

      setTimeout(() => {
        setModalStatus("closed");
        resetTranslate();
      }, 300);
    }
  }, [isOpen, resetTranslate]);

  return createPortal(
    <div
      className={classNames(styles.BottomSheet, styles[`modal-${modalStatus}`])}
      ref={sheetRef}
      {...handlers}
    >
      <Shared.HStack
        className={styles.sheetHeader}
        justify="center"
        align="center"
        role="button"
        onClick={() => onClose()}
      >
        <Shared.Icon name="sheetHandle" />
      </Shared.HStack>

      <div className={styles.sheetContent}>{children}</div>
    </div>,
    document.body
  );
};
