export const COOKIE_KEYS = {
  USER_ID: "userId",
  USER_SAVE_ID: "user_save_id",

  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  KEEP_LOGIN: "keep",

  TEMP_CART_ID: "tmp_cart_id",
} as const;
