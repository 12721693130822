import { useNavigate } from "react-router-dom";

export const LegacyLogoIcon = ({ onClick }) => {
  const navigate = useNavigate();
  const clickFC = () => {
    if (typeof onClick === "function") {
      onClick();
      navigate("/");
    } else {
      navigate("/");
    }
  };

  return (
    <div onClick={clickFC}>
      <img
        style={{ width: "40px", height: "40px" }}
        src="/assets/icons/icon_logo.svg"
        alt="사진없음"
      />
    </div>
  );
};

LegacyLogoIcon.defaultProps = {
  onClick: "",
};
