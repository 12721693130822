import type { MouseEventHandler } from "react";
import { useNavigate, type To } from "react-router-dom";
import Shared from "..";
import type { LinkButtonProps } from "../Button/LinkButton";
import type { TitleProps } from "./Title";
import { Title } from "./Title";

type Props = Pick<TitleProps, "title" | "count"> &
  Pick<LinkButtonProps, "disabledNavigate"> & {
    to: To;
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };

export const LinkTitle = ({
  title,
  to,
  count,
  disabledNavigate,
  onClick,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Shared.BaseButton
      onClick={e => {
        onClick?.(e);
        if (!disabledNavigate) {
          navigate(to as To);
        }
      }}
    >
      <Shared.HStack align="center" justify="between">
        <Title title={title} count={count} noPadding />

        <Shared.Icon name="chevronSmallRight" />
      </Shared.HStack>
    </Shared.BaseButton>
  );
};
