import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 * @deprecated
 */
export const LegacyBackIcon = ({ route, onClick }) => {
  const navigationPath = useSelector((state) => state.user.navigationPath);
  const navigate = useNavigate();

  const clickFC = () => {
    if (
      navigationPath.from !== "" &&
      navigationPath.from !== navigationPath.to
    ) {
      navigate(route); // default: -1
    } else {
      navigate("/");
    }
    if (typeof onClick === "function") {
      onClick();
    }
  };
  return (
    <div onClick={clickFC}>
      <img
        style={{ width: "20px", height: "20px" }}
        src="/assets/icons/icon_left_arrow.svg"
        alt="사진없음"
      />
    </div>
  );
};
LegacyBackIcon.defaultProps = {
  route: -1,
  onClick: () => undefined,
};
